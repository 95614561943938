import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { z } from 'zod';
import { useSettings } from '_api';
import { openURL } from '_utils';
import { track } from '_utils/Amplitude';
import { captureLeadForm } from '_utils/Sentry';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { PrimaryButton, HeimeText, Icon, FormInput } from 'Components';
import AuthNavigationHeader from './common/AuthNavigationHeader';
import { useAuthRoute } from '../AuthNavigation';

const formSchema = z.object({
    name: z.string().min(1),
    email: z.union([z.literal(''), z.string().email()]),
    phone: z.string().nullable(),
    message: z.string(),
});

const LeadForm = () => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const { params } = useAuthRoute<'LeadForm'>();
    const [isSent, setIsSent] = useState(false);

    const { data } = useSettings();

    const call = () => {
        track('Lead form phone button pressed');
        openURL(`tel:${data?.phone}`, t);
    };
    const mail = () => {
        track('Lead form email button pressed');
        openURL(`mailto:${data?.mail}`, t);
    };
    const website = () => {
        track('Lead form website button pressed');
        openURL('https://www.heime.com', t);
    };

    const handleConfirm = (formValues: z.infer<typeof formSchema>) => {
        track('Lead form submitted');
        captureLeadForm(
            {
                name: formValues.name,
                email: formValues.email ?? '',
                phone: formValues.phone ?? '',
            },
            formValues.message,
        );
        setIsSent(true);
    };

    const formValues = useForm({
        defaultValues: {
            name: '',
            phone: params.phone,
            email: '',
            message: '',
        },
        resolver: zodResolver(formSchema),
    });

    const contactMethods = [
        {
            icon: 'phone',
            text: data?.phone,
            onPress: call,
        },
        {
            icon: 'email',
            text: data?.mail,
            onPress: mail,
        },
        {
            icon: 'globe',
            text: 'https://www.heime.com',
            onPress: website,
        },
    ] as const;

    return (
        <>
            <AuthNavigationHeader />

            <View style={{ marginBottom: screenMargin, gap: smallestMargin, marginTop: screenMargin }}>
                <HeimeText variant="title">{t('leadForm:title')}</HeimeText>
                <HeimeText variant="subtitle" style={{ alignSelf: 'center' }}>
                    {t('leadForm:subTitle')}
                </HeimeText>
                <View style={{ gap: smallestMargin, alignSelf: 'center' }}>
                    {contactMethods.map((method) => (
                        <TouchableOpacity
                            key={method.text}
                            style={{ flexDirection: 'row', alignItems: 'center', gap: smallestMargin }}
                            onPress={method.onPress}
                        >
                            <Icon name={method.icon} color="main" />
                            <HeimeText style={{ fontSize: subtitleFontSize }}>{method.text}</HeimeText>
                        </TouchableOpacity>
                    ))}
                </View>
            </View>
            {isSent ? (
                <Animated.View style={{ flexGrow: 1, justifyContent: 'center' }} entering={FadeIn} exiting={FadeOut}>
                    <View
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 100,
                            width: 100,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: theme.success,
                            borderRadius: 100,
                            marginBottom: screenMargin,
                        }}
                    >
                        <Icon name="check" scale={4} color="white" />
                    </View>
                    <HeimeText variant="title">{t('leadForm:sent')}</HeimeText>
                </Animated.View>
            ) : (
                <View style={{ gap: smallestMargin }}>
                    <FormProvider {...formValues}>
                        <FormInput name="name" label={t('leadForm:name')} />
                        <FormInput name="email" label={t('leadForm:email')} />
                        <FormInput
                            name="message"
                            multiline={true}
                            numberOfLines={3}
                            placeholder={t('leadForm:message_placeholder')}
                            label={t('leadForm:message')}
                        />
                    </FormProvider>
                    <HeimeText style={{ fontSize: smallestFontSize, color: theme.greyMedium }}>
                        {t('leadForm:gdpr_details')}
                    </HeimeText>
                    <PrimaryButton
                        onPress={formValues.handleSubmit(handleConfirm)}
                        text={t('leadForm:send')}
                        style={{ marginBottom: screenMargin }}
                        status={formValues.formState.isValid ? null : 'disabled'}
                    />
                </View>
            )}
        </>
    );
};

export default LeadForm;
