import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import _fonts from '_fonts';
import no from '_localization/no.json';
import { WW, useThemeStyle } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { smallestFontSize } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import { Icon } from 'Components';
import { IconNames } from 'types/icons';

const NewPlusItem = ({
    title,
    icon,
    selected = false,
    onPress,
}: {
    title: Exclude<keyof typeof no.plusMenu, 'other' | 'recommended'>;
    icon: IconNames;
    selected?: boolean;
    onPress: () => void;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[
                selected ? themedStyle.mainSelected : themedStyle.main,
                selected
                    ? {
                          borderTopWidth: 2,
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          borderColor: theme.darkGreen,
                      }
                    : {
                          borderWidth: 1,
                          borderColor: theme.mediumBackground,
                      },
            ]}
        >
            <View style={themedStyle.iconWrapper}>
                <Icon name={icon} color="main" />
            </View>
            <View style={themedStyle.content}>
                <Text style={themedStyle.title}>
                    {t(`plusMenu:${title}:title`, {
                        boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                    })}
                </Text>
                <Text style={themedStyle.subTitle}>
                    {t(`plusMenu:${title}:description`, {
                        boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                    })}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: WW * 0.02,
        },
        mainSelected: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: WW * 0.02,
        },
        content: {
            paddingLeft: WW * 0.04,
            flex: 1,
        },
        iconWrapper: {
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            borderRadius: WW * 3,
            backgroundColor: theme.lightGreen,
        },
        title: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.darkGreen,
            fontSize: WW * 0.04,
        },
        subTitle: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: smallestFontSize,
            lineHeight: smallestFontSize * 1.5,
        },
    });

export default NewPlusItem;
