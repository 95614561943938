import React, { ReactElement, useMemo } from 'react';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import FastImage from '_dependencies/fastImage';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { PrimaryButton, BackArrow, LanguageSelector, HeimeText } from 'Components';
import countryCallingCodes from './countryCallingCodes';
import Title from './Title';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const SelectNumber = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'SelectNumber'>();

    const handleGoBack = () => {
        navigation.pop();
    };

    const matched = useMemo(() => {
        // Get country codes for both numbers
        const firstCountryCode = getCountryCode(params.firstNumber);
        const secondCountryCode = getCountryCode(params.secondNumber);

        return [
            {
                sections: getSections(params.firstNumber, params.secondNumber),
                number: params.firstNumber,
                countryCode: firstCountryCode,
            },
            {
                sections: getSections(params.secondNumber, params.firstNumber),
                number: params.secondNumber,
                countryCode: secondCountryCode,
            },
        ];
    }, [params.firstNumber, params.secondNumber]);

    const getHandleSelectNumber = (number: string) => () => {
        navigation.navigate('SelectRole', { phone: number });
    };

    return (
        <>
            <View style={themedStyle.navWrapper}>
                <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                    <BackArrow />
                    <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
                </TouchableOpacity>
                <LanguageSelector />
            </View>

            <Title style={themedStyle.title} text={t('selectNumber:title')} />
            <View style={themedStyle.textContainer}>
                {matched.map((item, i) => (
                    <View key={i} style={themedStyle.numberContainer}>
                        <View style={themedStyle.flagContainer}>
                            <FastImage
                                source={{
                                    uri: `https://flagcdn.com/h40/${item.countryCode.toLowerCase()}.webp`,
                                }}
                                style={themedStyle.flagStyle}
                                resizeMode="contain"
                            />
                        </View>
                        <View style={themedStyle.sectionsContainer}>
                            {item.sections.map((section, index) => (
                                <HeimeText
                                    key={`${section.section}-${index}`}
                                    style={[
                                        themedStyle.text,
                                        section.matches ? themedStyle.textMatches : themedStyle.textNotMatching,
                                    ]}
                                >
                                    {section.section}
                                </HeimeText>
                            ))}
                        </View>
                        <PrimaryButton
                            style={themedStyle.selectButton}
                            onPress={getHandleSelectNumber(item.number)}
                            text={t('selectNumber:select')}
                        />
                    </View>
                ))}
            </View>
        </>
    );
};

// Function to find the country code from a phone number, even if it's not valid
const findCountryCodeFromNumber = (number: string): { countryCode: string; callingCode: string } => {
    // Remove any non-digit characters and ensure it doesn't start with +
    const cleanNumber = number.replace(/\D/g, '').replace(/^\+/, '');
    // Try to find the longest matching country calling code
    let matchedCallingCode = '';
    let matchedCountryCode = '';

    // Sort calling codes by length (descending) to match the longest code first
    const sortedCallingCodes = Object.keys(countryCallingCodes).sort((a, b) => b.length - a.length);

    for (const callingCode of sortedCallingCodes) {
        if (cleanNumber.startsWith(callingCode)) {
            matchedCallingCode = callingCode;
            matchedCountryCode = countryCallingCodes[callingCode];
            break;
        }
    }

    return {
        countryCode: matchedCountryCode || 'unknown',
        callingCode: matchedCallingCode || '',
    };
};

// Get the country code (ISO 2-letter code) from a phone number
const getCountryCode = (number: string): string => {
    try {
        // Always ensure the number starts with a +
        const formattedNumber = number.startsWith('+') ? number : `+${number}`;

        if (isValidNumber(formattedNumber)) {
            const phoneNumber = parsePhoneNumber(formattedNumber);
            return phoneNumber.country?.toLowerCase() || 'unknown';
        } else {
            // If the number is not valid, try to extract the country code using our lookup
            const cleanNumber = number.replace(/\D/g, '');
            const { countryCode } = findCountryCodeFromNumber(cleanNumber);
            return countryCode;
        }
    } catch (error) {
        // If libphonenumber-js fails, try our custom lookup
        const cleanNumber = number.replace(/\D/g, '');
        const { countryCode } = findCountryCodeFromNumber(cleanNumber);
        return countryCode;
    }
};

const getSections = (number: string, otherNumber: string): { section: string; matches: boolean }[] => {
    const sections: { section: string; matches: boolean }[] = [];

    // Ensure numbers are formatted correctly
    const formattedNumber = number.startsWith('+') ? number : `+${number}`;
    const formattedOtherNumber = otherNumber.startsWith('+') ? otherNumber : `+${otherNumber}`;

    // Always extract the country calling code
    let countryCallingCode = '';
    let otherCountryCallingCode = '';
    let remainingNumber = '';
    let remainingOtherNumber = '';

    // Try to get the country calling code using libphonenumber-js first
    if (isValidNumber(formattedNumber)) {
        const phoneNumber = parsePhoneNumber(formattedNumber);
        countryCallingCode = phoneNumber.countryCallingCode;
        remainingNumber = formattedNumber.substring(countryCallingCode.length + 1); // +1 for the + sign
    } else {
        // If the number is not valid, try to extract the country code using our lookup
        const cleanNumber = number.replace(/\D/g, '');
        const result = findCountryCodeFromNumber(cleanNumber);
        countryCallingCode = result.callingCode;
        remainingNumber = cleanNumber.substring(countryCallingCode.length);
    }

    if (isValidNumber(formattedOtherNumber)) {
        const otherPhoneNumber = parsePhoneNumber(formattedOtherNumber);
        otherCountryCallingCode = otherPhoneNumber.countryCallingCode;
        remainingOtherNumber = formattedOtherNumber.substring(otherCountryCallingCode.length + 1); // +1 for the + sign
    } else {
        // If the number is not valid, try to extract the country code using our lookup
        const cleanOtherNumber = otherNumber.replace(/\D/g, '');
        const result = findCountryCodeFromNumber(cleanOtherNumber);
        otherCountryCallingCode = result.callingCode;
        remainingOtherNumber = cleanOtherNumber.substring(otherCountryCallingCode.length);
    }

    // Always add the + sign and country code as the first section
    sections.push({
        section: `+${countryCallingCode}`,
        matches: countryCallingCode === otherCountryCallingCode,
    });

    // Process the remaining parts of the number
    let currentIndex = 0;
    while (currentIndex < remainingNumber.length && currentIndex < remainingOtherNumber.length) {
        const [equalResult, afterEqualIndex] = takeWhile(
            remainingNumber.substring(currentIndex),
            remainingOtherNumber.substring(currentIndex),
            (a, b) => a === b,
        );

        if (equalResult) {
            sections.push({ section: equalResult, matches: true });
        }
        currentIndex += afterEqualIndex;

        if (currentIndex < remainingNumber.length && currentIndex < remainingOtherNumber.length) {
            const [unequalResult, afterUnequalIndex] = takeWhile(
                remainingNumber.substring(currentIndex),
                remainingOtherNumber.substring(currentIndex),
                (a, b) => a !== b,
            );
            sections.push({ section: unequalResult, matches: false });
            currentIndex += afterUnequalIndex;
        }
    }

    // Handle remaining sections of the first number
    if (currentIndex < remainingNumber.length) {
        const currentSection = remainingNumber.slice(currentIndex);
        sections.push({ section: currentSection, matches: false });
    }

    return sections;
};

const takeWhile = (a: string, b: string, predicate: (letterA: string, letterB: string) => boolean) => {
    let currentIndex = 0;
    let currentSection = '';

    while (
        currentIndex < a.length &&
        currentIndex < b.length &&
        predicate(a[currentIndex], b[currentIndex]) &&
        currentSection.length < 3
    ) {
        currentSection += a[currentIndex];
        currentIndex++;
    }

    return [currentSection, currentIndex] as const;
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        navWrapper: { flexDirection: 'row', alignItems: 'center' },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
        title: {
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24,
            marginBottom: 36,
            textAlign: 'left',
        },
        textContainer: {
            flexShrink: 0,
            flex: 1,
        },
        text: {
            flexShrink: 0,
            color: theme.black,
            marginHorizontal: smallestMargin,
        },
        flagContainer: {
            width: 32,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: smallestMargin,
        },
        flagStyle: {
            width: 32,
            height: 32,
            overflow: 'hidden',
        },
        numberContainer: {
            alignItems: 'center',
            marginBottom: screenMargin,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.lightGreen,
            padding: smallestMargin,
            margin: smallestMargin,
            flexDirection: 'row',
        },
        sectionsContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        selectButton: {
            flexGrow: 0,
            marginLeft: 'auto',
        },
        textMatches: {
            fontSize: smallestFontSize,
        },
        textNotMatching: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
        },
    });

export default SelectNumber;
