import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { useAuthNavigation } from 'Auth/AuthNavigation';
import BackArrow from 'Components/BackArrow';
import HeimeText from 'Components/HeimeText';
import LanguageSelector from 'Components/LanguageSelector';

const AuthNavigationHeader = ({ horizontalMargin = false }: { horizontalMargin?: boolean }) => {
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { theme } = useThemeContext();

    const handleGoBack = () => {
        navigation.pop();
    };

    return (
        <View
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginHorizontal: horizontalMargin ? screenMargin : 0,
            }}
        >
            <TouchableOpacity
                onPress={handleGoBack}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <BackArrow />
                <HeimeText
                    style={{
                        fontSize: 16,
                        letterSpacing: 1,
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: theme.main,
                    }}
                >
                    {t('enterPin:back')}
                </HeimeText>
            </TouchableOpacity>
            <LanguageSelector />
        </View>
    );
};

export default AuthNavigationHeader;
