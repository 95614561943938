import { ReactElement } from 'react';
import { View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { HeimeText } from 'Components';

const BlendedEmptyState = ({ title, subtitle }: { title: string; subtitle: string | ReactElement }) => {
    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: screenMargin * 2,
            }}
        >
            <HeimeText
                variant="title"
                style={{
                    marginBottom: screenMargin,
                }}
            >
                {title}
            </HeimeText>
            <HeimeText
                variant="subtitle"
                style={{
                    textAlign: 'center',
                }}
            >
                {subtitle}
            </HeimeText>
        </View>
    );
};

export default BlendedEmptyState;
