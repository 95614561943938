import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Animated, {
    Easing,
    interpolate,
    useAnimatedProps,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';
import { Svg, Circle } from 'react-native-svg';
import { useOwnProfile } from '_api/useProfile';
import { useAppNavigation } from '_navigator';
import { Theme, WW, useThemeStyle } from '_utils';
import { track } from '_utils/Amplitude';
import useIsHelpfulNeighbor from '_utils/messages/useIsHelpfulNeighbor';
import { screenMargin, smallestFontSize, smallestMargin, smallestMarginPixels, subtitleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { HeimeText, Icon, InformationBubble, Modal } from 'Components';
import { OwnUserProfile } from 'types/User';

const getPercentageCompletion = (profile: OwnUserProfile, joinedGroup: boolean, isHelpfulNeighbor: boolean) => {
    let completion = 0;
    if (profile.avatar) {
        completion += 35;
    }
    if (profile.about_me) {
        completion += 15;
    }
    if (profile.email) {
        completion += 15;
    }
    if (profile.birthday) {
        completion += 10;
    }
    if (joinedGroup) {
        completion += 15;
    }
    if (isHelpfulNeighbor) {
        completion += 10;
    }
    return completion;
};

const ProfileCompletion = ({ joinedGroup }: { joinedGroup: boolean }) => {
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const themedStyle = useThemeStyle(style);
    const [modalOpen, setModalOpen] = useState(false);
    const { data: ownProfile } = useOwnProfile();
    const isHelper = useIsHelpfulNeighbor(ownProfile?.id ?? 0);

    const percentage = useMemo(
        () => getPercentageCompletion(ownProfile!, joinedGroup, isHelper),
        [ownProfile, joinedGroup, isHelper],
    );
    const openModal = () => {
        track('profile_completion_modal_open');
        setModalOpen(true);
    };
    const closeModal = () => setModalOpen(false);
    const openProfile = () => {
        track('profile_completion_modal_open_profile');
        closeModal();
        navigate('Profile', undefined);
    };
    const addAboutMe = () => {
        track('profile_completion_modal_add_about_me');
        closeModal();
        navigate('ProfileAboutMe', undefined);
    };
    const joinGroups = () => {
        track('profile_completion_modal_join_groups');
        closeModal();
        navigate('GroupsAll', undefined);
    };

    const becomeHelpfulNeighbor = () => {
        track('Clicked become helpful neighbor in profile completion modal');
        closeModal();
        navigate('HelpfulNeighbors', undefined);
    };

    if (percentage === 100) {
        return null;
    }

    return (
        <>
            <InformationBubble
                title={t('profile:profileCompletion')}
                description={t('profile:profileCompletionDescription')}
                content={<PercentageCompletion percent={percentage} />}
                onPress={openModal}
                style={themedStyle.container}
            />
            {modalOpen ? (
                <Modal
                    header={t('profile:profileCompletionModalHeader')}
                    onDismiss={() => setModalOpen(false)}
                    content={
                        <>
                            <HeimeText style={themedStyle.modalDescription}>
                                {t('profile:profileCompletionModalDescription')}
                            </HeimeText>
                            <HorizontalProgress percent={percentage} />

                            <Step
                                done={Boolean(ownProfile?.avatar)}
                                text={t('profile:profileCompletionModalAvatar')}
                                onPress={openProfile}
                            />
                            <Step
                                done={Boolean(ownProfile?.about_me)}
                                text={t('profile:profileCompletionModalAboutMe')}
                                onPress={addAboutMe}
                            />
                            <Step
                                done={Boolean(ownProfile?.email)}
                                text={t('profile:profileCompletionModalEmail')}
                                onPress={openProfile}
                            />
                            <Step
                                done={Boolean(ownProfile?.birthday)}
                                text={t('profile:profileCompletionModalBirthday')}
                                onPress={openProfile}
                            />
                            <Step
                                done={isHelper}
                                text={t('profile:profileCompletionModalIsHelpfulNeighbor')}
                                onPress={becomeHelpfulNeighbor}
                            />
                            <Step
                                done={joinedGroup}
                                text={t('profile:profileCompletionModalJoinGroup')}
                                onPress={joinGroups}
                            />
                        </>
                    }
                    buttons={[
                        {
                            text: t('profile:profileCompletionModalButton'),
                            onPress: () => setModalOpen(false),
                            type: 'secondary',
                        },
                    ]}
                />
            ) : null}
        </>
    );
};

const radius = WW * 0.08;
const strokeWidth = WW * 0.02;
const size = (radius + strokeWidth) * 2;
const circumference = radius * 2 * Math.PI;
const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const PercentageCompletion = ({
    percent,
}: {
    percent: number; // value between 0 - 100
}) => {
    const { theme } = useContext(ThemeContext);
    const themedStyle = useThemeStyle(style);

    const progressValue = useSharedValue(0);

    useEffect(() => {
        progressValue.value = withTiming(percent, { duration: 300, easing: Easing.out(Easing.quad) });
    }, [percent, progressValue]);

    const animatedProps = useAnimatedProps(() => {
        const strokeDashoffset = interpolate(progressValue.value, [0, 100], [circumference, 0]);

        return {
            strokeDashoffset,
        };
    });

    return (
        <View>
            <Svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
                <Circle
                    cx={radius + strokeWidth}
                    cy={radius + strokeWidth}
                    r={radius}
                    stroke={theme.lightGrey}
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                <AnimatedCircle
                    cx={radius + strokeWidth}
                    cy={radius + strokeWidth}
                    r={radius}
                    stroke={theme.main}
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    animatedProps={animatedProps}
                    rotation={'-90'}
                    origin={`${radius + strokeWidth}, ${radius + strokeWidth}`}
                />
            </Svg>
            <View style={themedStyle.percentageTextContainer}>
                <HeimeText style={themedStyle.percentageText}>{`${percent}%`}</HeimeText>
            </View>
        </View>
    );
};

const HorizontalProgress = ({ percent }: { percent: number }) => {
    const themedStyle = useThemeStyle(style);
    const progressValue = useSharedValue(0);

    useEffect(() => {
        progressValue.value = withTiming(percent, { duration: 300, easing: Easing.out(Easing.quad) });
    }, [percent, progressValue]);

    const animatedStyle = useAnimatedStyle(() => ({
        width: `${progressValue.value}%`,
    }));

    return (
        <View style={themedStyle.horizontalContainer}>
            <View style={themedStyle.progressContainer}>
                <Animated.View style={[themedStyle.progressBar, animatedStyle]} />
            </View>
            <HeimeText style={themedStyle.progressDescription}>{percent}%</HeimeText>
        </View>
    );
};

const Step = ({
    done,
    text,
    onPress,
    hasBorder = true,
}: {
    done: boolean;
    text: string;
    onPress: () => void;
    hasBorder?: boolean;
}) => {
    const themedStyle = useThemeStyle(style);

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[themedStyle.stepContainer, hasBorder ? themedStyle.stepContainerBorder : null]}
        >
            <View style={done ? null : themedStyle.stepCheckBorder}>
                {done ? <Icon name="checkCircle" color="main" /> : null}
            </View>
            <HeimeText style={themedStyle.stepText}>{text}</HeimeText>
            <Icon name="chevron" color="main" style={{ flexShrink: 0 }} />
        </TouchableOpacity>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        container: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        percentageTextContainer: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: size,
            height: size,
            justifyContent: 'center',
            alignItems: 'center',
        },
        percentageText: {
            fontSize: smallestFontSize,
            color: theme.main,
        },
        progressContainer: {
            flex: 1,
            borderRadius: 4,
            overflow: 'hidden',
            height: 9,
            backgroundColor: theme.lightGrey,
        },
        progressBar: {
            height: '100%',
            borderRadius: 4,
            backgroundColor: theme.main,
        },
        horizontalContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: smallestMargin,
            marginBottom: smallestMarginPixels * 2,
        },
        progressDescription: {
            marginLeft: smallestMargin,
            fontSize: smallestFontSize,
            color: theme.main,
        },
        modalDescription: {
            fontSize: subtitleFontSize,
            color: theme.mediumGrey,
            marginBottom: smallestMargin,
            marginTop: smallestMargin,
        },
        stepContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: smallestMargin,
            paddingBottom: smallestMarginPixels * 1.5,
            paddingTop: smallestMarginPixels * 1.5,
        },
        stepContainerBorder: {
            borderBottomWidth: 1,
            borderBottomColor: theme.lightGrey,
        },
        stepCheckBorder: {
            width: 24,
            height: 24,
            borderWidth: 1,
            borderColor: theme.mediumGrey,
            borderRadius: 12,
        },
        stepText: {
            fontSize: subtitleFontSize,
            color: theme.main,
            flexGrow: 1,
            flexShrink: 1,
        },
    });

export default ProfileCompletion;
