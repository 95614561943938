import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';
import isAppError from './isAppError';
import isNetworkError from './isNetworkError';
import {
    addBreadcrumbTypeExport,
    captureExceptionExport,
    captureLeadFormExport,
    captureUserFeedbackExport,
    initializeSentryType,
    registerNavigationType,
    removeSentryCooperativeType,
    removeSentryUserType,
    sentryInterceptorExport,
    setSentryCooperativeType,
    setSentryUserType,
    WithSentryExport,
} from './SentryExport';
import { API_URL } from '../_constants';
import { AppError } from '../types/Utility';

const initializeSentry: initializeSentryType = (): void => {
    const environment =
        __DEV__ || API_URL('no')?.includes('heime-dev:8000') || API_URL('no')?.includes('localhost:8000')
            ? 'LOCAL_DEV'
            : API_URL('no')?.includes('dev.frontsoftware.no/heime') ||
                API_URL('no')?.includes('heime.dev.frontsoftware.no/heime')
              ? 'DEV'
              : 'Production';

    Sentry.init({
        dsn: 'https://ec0b1b36b34446c390af280160202270@o1053966.ingest.sentry.io/6039008',
        tracesSampleRate: environment === 'Production' ? 0.05 : environment === 'DEV' ? 0.1 : 0,
        sampleRate: environment === 'LOCAL_DEV' ? 0 : 1,
        environment,
        integrations: [browserTracingIntegration()],
    });
};

const registerNavigation: registerNavigationType = (): void => {};

const sentryInterceptor: sentryInterceptorExport = (error: Error | AppError<unknown>): void => {
    if (isAppError(error) && isNetworkError(error)) {
        // We do not track those
        throw error;
    }
    if (isAppError(error)) {
        const axiosError = error;
        const response = axiosError.response;
        const data = JSON.stringify({
            statusCode: response?.status,
            statusText: response?.statusText,
            message: axiosError?.message,
            data: response?.data,
            url: response?.config.url,
            body: axiosError.request.data,
        });
        addBreadcrumb('requestError', data, 'error');

        const newError = new Error(`${error.message}, data:${data}`);
        captureException(newError);
        (newError as AppError<unknown>).response = response;
        throw newError;
    } else {
        captureException(error);
    }
    throw error;
};

const captureException: captureExceptionExport = (error, context): void => {
    Sentry.captureException(error, context as undefined);
};

const addBreadcrumb: addBreadcrumbTypeExport = (category, message, level): void => {
    Sentry.addBreadcrumb({ category, message, level });
};

// NOOP
const withSentry: WithSentryExport = (input) => input;

const setSentryUser: setSentryUserType = (id: string): void => {
    Sentry.setUser({ id, timezone: new Date().getTimezoneOffset() / -60 });
};
const removeSentryUser: removeSentryUserType = (): void => {
    Sentry.setUser(null);
};

const setSentryCooperative: setSentryCooperativeType = (coop: { id: number; name: string }): void => {
    Sentry.setContext('selectedcoop', coop);
};
const removeSentryCooperative: removeSentryCooperativeType = (): void => {
    Sentry.setContext('selectedcoop', null);
};

const captureUserFeedback: captureUserFeedbackExport = (origin, message, userName, allowedFollowUp = false) => {
    Sentry.captureMessage(`User feedback received from ${origin}`, {
        tags: {
            allowed_follow_up: allowedFollowUp,
        },
    });

    Sentry.captureFeedback({
        associatedEventId: Sentry.lastEventId() ?? '',
        message,
        email: 'anonymized@heime.com',
        name: userName,
    });
};

const captureLeadForm: captureLeadFormExport = (
    user: { name: string; email: string; phone: string },
    message: string,
): void => {
    Sentry.captureMessage('Lead form submitted');

    Sentry.captureFeedback({
        associatedEventId: Sentry.lastEventId() ?? '',
        message,
        email: user.email,
        name: `${user.name} (${user.phone})`,
    });
};

export {
    initializeSentry,
    withSentry,
    sentryInterceptor,
    captureException,
    addBreadcrumb,
    registerNavigation,
    setSentryUser,
    removeSentryUser,
    setSentryCooperative,
    removeSentryCooperative,
    captureUserFeedback,
    captureLeadForm,
};
