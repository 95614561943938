import { useMemo } from 'react';
import { useContextMenuFocusEffect } from '_navigator';
import { MessageType } from 'types/message';

const useSharingContextMenuEffect = (types: MessageType[]): void => {
    const highlights = useMemo(() => {
        const innerTypes: Parameters<typeof useContextMenuFocusEffect>[0] = [];
        if (types.includes(MessageType.ByBoard) || types.includes(MessageType.Requests)) {
            innerTypes.push('noticeNew');
            innerTypes.push('noticeByBoard');
        }
        if (types.includes(MessageType.Products) && !types.includes(MessageType.Helpers)) {
            innerTypes.push('sharingNew');
        }
        innerTypes.push('helpNew');
        if (types.includes(MessageType.Helpers)) {
            innerTypes.push('helpfulNeighbour');
            if (types.includes(MessageType.Products)) {
                innerTypes.push('sharingNew');
            }
        }

        return innerTypes;
    }, [types]);
    useContextMenuFocusEffect(highlights);
};

export default useSharingContextMenuEffect;
