import { useIsFocused } from '@react-navigation/native';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import safeParse from '_utils/safeParse';
import { ApiImageSchema, FileImageSchema } from 'types/Base';

const RegistrationSchema = z.object({
    status: z.union([z.literal('pending'), z.literal('approved'), z.literal('declined')]),
    reason: z.string().nullable(),
    reason_author: z
        .object({
            id: z.number(),
            name: z.string(),
            avatar: z.union([ApiImageSchema, FileImageSchema]).nullable(),
        })
        .nullable(),
    phone: z.string(),
    fname: z.string(),
    lname: z.string(),
    email: z.string().nullable(),
    assigned_cooperative: z
        .object({
            id: z.number(),
            name: z.string(),
        })
        .nullable(),
    assigned_apartment: z
        .object({
            id: z.number(),
            apartmentName: z.string(),
        })
        .nullable(),
    cooperative_guess: z.string(),
    apartment_guess: z.string(),
    norwegian_user_registration: z
        .object({
            kommunenummer: z.string(),
            gardsnummer: z.string(),
            bruksnummer: z.string(),
            adressenavn: z.string(),
            nummer: z.string(),
            bokstav: z.string(),
            bruksenhetsnummer: z.string(),
        })
        .nullable(),
});

export type Registration = z.infer<typeof RegistrationSchema>;

const useGetRegistration = (registrationId: string): UseQueryResult<Registration, string | Error> => {
    const isFocused = useIsFocused();
    return useQuery({
        queryKey: ['registrations', registrationId],

        queryFn: async () => {
            const result = await axios.get<unknown>(`registrations/${registrationId}`);
            return safeParse(result.data, RegistrationSchema);
        },

        gcTime: 1000 * 60 * 60 * 24 * 30,
        staleTime: 1000 * 60,
        enabled: isFocused,
        retry: 0,
    });
};

export type NorwegianUserRegistration = {
    kommunenummer: string;
    gardsnummer: string;
    bruksnummer: string;
    adressenavn: string;
    nummer: string;
    bokstav: string;
    bruksenhetsnummer: string;
};

type RegistrationMutation = {
    phone: string;
    fname: string;
    lname: string;
    email: string;
} & (
    | {
          norwegian_user_registration?: NorwegianUserRegistration;
      }
    | {
          cooperative_guess: string;
          apartment_guess: string;
      }
);

const useCreateRegistration = (): UseMutationResult<string, string | Error, RegistrationMutation> => {
    return useMutation({
        mutationFn: async (profile: RegistrationMutation) => {
            const result = await axios.post<{ success: string }>('registrations', profile);
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
            return result.data.success;
        },
    });
};

const useInvalidateRegistrations = () => {
    const queryClient = useQueryClient();
    return () => {
        queryClient.invalidateQueries({
            queryKey: ['registrations'],
        });
    };
};

const useUpdateRegistration = (
    registrationId: string,
): UseMutationResult<unknown, string | Error, RegistrationMutation> => {
    const onSettled = useInvalidateRegistrations();
    return useMutation({
        mutationFn: async (profile: RegistrationMutation) => {
            const result = await axios.patch<{ success: boolean }>(`registrations/${registrationId}`, profile);
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
        },
        onSettled,
    });
};

const useDeleteRegistration = (registrationId: string): UseMutationResult<unknown, string | Error> => {
    const onSettled = useInvalidateRegistrations();
    return useMutation({
        mutationFn: async () => {
            const result = await axios.delete<{ success: boolean }>(`registrations/${registrationId}`);
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
        },
        onSettled,
    });
};

export { useGetRegistration, useCreateRegistration, useUpdateRegistration, useDeleteRegistration };
