import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { z } from 'zod';
import safeParse from '_utils/safeParse';
import { RichText } from 'types/Base';
import { MessageType, Message, isNoticeMessage, MessageSchema } from 'types/message';
import { useInvalidateNotifications } from './useNotifications';
import { useOwnProfile } from './useProfile';
import { getFileObjectFunc, getPlaceholderSearchData, useLaravelInfinteQuery } from './utility';
import { getUrlExtension } from '../_utils';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import {
    FileToBeUploaded,
    GetFileToBeUploadedToApiFile,
    isFileToBeUploaded,
    LaravelPagingResponse,
} from '../types/Utility';

export const invalidateMessageQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['messages'],
    });
    queryClient.invalidateQueries({
        queryKey: ['message'],
    });
};

const messagesArraySchema = z.array(MessageSchema);

const useGetMessages = (
    messageTypes: MessageType[],
    search = '',
    includeInactive = false,
): UseInfiniteQueryResult<InfiniteData<Message[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    const isFocused = useIsFocused();
    const mappedTypes = messageTypes?.map((item) => (typeof item === 'string' ? MessageType[item] : item));
    return useLaravelInfinteQuery(
        ['messages', selectedCoopId, mappedTypes, search, includeInactive],
        async ({ pageParam = 1 }) => {
            return await axios.get(`cooperatives/${selectedCoopId}/messages`, {
                headers: { authorization: await getAuthHeader() },
                params: {
                    page: pageParam,
                    types: mappedTypes,
                    search,
                    includeInactive,
                },
            });
        },
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60,
            enabled: isFocused,
            placeholderData: getPlaceholderSearchData(
                queryClient,
                {
                    queryKey: ['messages', selectedCoopId],
                },
                (message) => {
                    if (!mappedTypes.includes(message.type)) {
                        return false;
                    }
                    if (
                        'active_until' in message &&
                        message.active_until &&
                        moment().isAfter(moment(message.active_until)) &&
                        !includeInactive
                    ) {
                        return false;
                    }

                    if (message.title.toLowerCase().includes(search.toLowerCase())) {
                        return true;
                    }
                    const searchRichText = (richText: RichText): boolean => {
                        if (richText.type === 'hardBreak') {
                            return false;
                        }
                        if (richText.type === 'text') {
                            return richText.text?.toLowerCase().includes(search.toLowerCase()) ?? false;
                        }
                        return richText.content?.some((item) => searchRichText(item)) ?? false;
                    };
                    return Boolean(message?.richtext_content && searchRichText(message?.richtext_content));
                },
            ),
        },
        messagesArraySchema,
    );
};

const useGetMessage = (messageId: number): UseQueryResult<Message, string | Error> => {
    const isFocused = useIsFocused();
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['message', selectedCoopId, messageId],

        queryFn: async () => {
            const result = await axios.get<Message>(`cooperatives/${selectedCoopId}/messages/${messageId}`, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('Result returned with no data');
            }

            return safeParse(result.data, MessageSchema);
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() && item.queryKey?.[0] === 'messages' && item.queryKey?.[1] === selectedCoopId,
                );

            let result: Message | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<Message[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((message: Message) => {
                        if (messageId === message.id) {
                            result = message;
                        }
                    }),
                ),
            );
            return result;
        },

        initialDataUpdatedAt: moment().subtract({ minutes: 2 }).valueOf(),
        enabled: isFocused,
    });
};

export interface MutateMessageBody {
    title: string;
    richtext_content: object;
    type?: number;
    product_id?: number | null;
    pictures?: string[];
    removePictures?: string[];
    files?: string[];
    removeFiles?: string[];
    cooperative_group_id: number | null;
    allow_comments?: boolean;
    send_notification?: boolean;
    apartment_groups: string[];
    active_until: string | null;
}

const useCreateMessage = (): UseMutationResult<
    { success: number },
    string | Error | AxiosError<{ message?: string }>,
    Omit<MutateMessageBody, 'removeFiles' | 'removePictures'>
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (message: MutateMessageBody) => {
            const body = {
                ...message,
                richtext_content: message.richtext_content,
            };
            const result = await axios.post<{ success: number }>(`cooperatives/${selectedCoopId}/messages`, body, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data.success) {
                throw new Error('Message creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['users'],
            });
        },
    });
};

const useEditMessage = (): UseMutationResult<{ success: number }, string | Error, [number, MutateMessageBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([messageId, message]: [number, MutateMessageBody]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}`,
                message,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Message edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [messageId]) => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['message', selectedCoopId, messageId],
            });
        },
    });
};

const useProlongMessage = (): UseMutationResult<{ success: number }, string | Error, [number, string]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const invalidateNotifications = useInvalidateNotifications();

    return useMutation({
        mutationFn: async ([messageId, active_until]: [number, string]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}/active_until`,
                { active_until },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Message edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [messageId]) => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['message', selectedCoopId, messageId],
            });
            invalidateNotifications();
        },
    });
};

const useDeleteMessage = (): UseMutationResult<{ success: boolean }, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (messageId: number) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Sharing deletetation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
        },
    });
};

export const messageMutationKey = 'messages';
const useMarkMessageRead = (): UseMutationResult<{ success: true }, string | Error, number[]> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();

    return useMutation({
        mutationKey: [messageMutationKey],
        mutationFn: async (messageIds: number[]) => {
            const messagesToMarkRead = messageIds.map(async (messageId) => {
                return await axios.patch(`cooperatives/${selectedCoopId}/messages/${messageId}/read`, undefined, {
                    headers: { authorization: await getAuthHeader() },
                });
            });

            const result = await Promise.all(messagesToMarkRead ?? []);

            if (!result.length) {
                throw new Error('Marking read return unsuccessful result');
            }

            return result[0].data;
        },
        onMutate: (chatId) => {
            queryClient.setQueryData<InfiniteData<LaravelPagingResponse<Message[]>> | undefined>(
                ['messages'],
                (response) =>
                    response
                        ? JSON.parse(
                              JSON.stringify({
                                  ...response,
                                  pages: response.pages.map((laravelResponse) => ({
                                      ...laravelResponse,
                                      data: laravelResponse.data.map((message) =>
                                          chatId.includes(message.id)
                                              ? message
                                              : {
                                                    ...message,
                                                    ...(isNoticeMessage(message)
                                                        ? {
                                                              unreadUsers: message.unreadUsers.filter(
                                                                  (item) => item !== ownProfile?.id,
                                                              ),
                                                          }
                                                        : {
                                                              readUsers: [...message.readUsers, ownProfile?.id ?? 0],
                                                          }),
                                                },
                                      ),
                                  })),
                              }),
                          )
                        : response,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

const updateMessage = (
    queryClient: QueryClient,
    selectedCoopId: number,
    messageId: number,
    updateFunc: (old: Message) => Message,
) => {
    queryClient.setQueryData<Message | undefined>(['message', selectedCoopId, messageId], (oldData) => {
        if (!oldData) {
            return oldData;
        }

        return updateFunc(oldData);
    });
};

interface MutateCommentMessageBody {
    content: string;
    files: FileToBeUploaded[];
}

const useAddMessageComment = (): UseMutationResult<number, string | Error, [number, MutateCommentMessageBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();

    return useMutation({
        mutationFn: async ([messageId, form]: [number, MutateCommentMessageBody]) => {
            const body = {
                ...form,
                files: form.files?.filter(isFileToBeUploaded).map(getFileObjectFunc(`${Math.random() * 10000}`)) ?? [],
            };

            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}/comments`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Message comment creation return unsuccessful result');
            }
            return result.data.success;
        },
        onMutate: ([messageId, form]) => {
            updateMessage(queryClient, selectedCoopId, messageId, (oldData) =>
                isNoticeMessage(oldData)
                    ? JSON.parse(
                          JSON.stringify({
                              ...oldData,
                              subscribers: [...oldData.subscribers, ownProfile?.id ?? 0],
                              comments: [
                                  ...oldData.comments,
                                  {
                                      id: null,
                                      creator: ownProfile?.id ?? 0,
                                      content: form.content,
                                      created_at: moment().unix(),
                                      files: form.files.filter(isFileToBeUploaded).map((file) => ({
                                          last_modified: moment().unix(),
                                          ext: getUrlExtension(file.uri),
                                          id: 1,
                                          name: file.name ?? '',
                                          original: file.uri,
                                      })),
                                  },
                              ],
                          }),
                      )
                    : oldData,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['messages'] });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

const useSubscribeToMessage = (): UseMutationResult<number, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();

    return useMutation({
        mutationFn: async (messageId: number) => {
            const result = await axios.put<{ success: number }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}/subscribers`,
                undefined,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Message creation return unsuccessful result');
            }
            return result.data.success;
        },
        onMutate: (messageId) => {
            updateMessage(queryClient, selectedCoopId, messageId, (oldData) =>
                isNoticeMessage(oldData)
                    ? {
                          ...oldData,
                          subscribers: [...oldData.subscribers, ownProfile?.id ?? 0],
                      }
                    : oldData,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

const useUnsubscribeToMessage = (): UseMutationResult<number, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();

    return useMutation({
        mutationFn: async (messageId: number) => {
            const result = await axios.delete<{ success: number }>(
                `cooperatives/${selectedCoopId}/messages/${messageId}/subscribers`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Message creation return unsuccessful result');
            }
            return result.data.success;
        },
        onMutate: (messageId) => {
            updateMessage(queryClient, selectedCoopId, messageId, (oldData) =>
                isNoticeMessage(oldData)
                    ? {
                          ...oldData,
                          subscribers: oldData.subscribers.filter((item) => item !== ownProfile?.id),
                      }
                    : oldData,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['messages'],
            });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

const useDeleteMessageComment = (): UseMutationResult<
    { success: true },
    string | Error,
    { commentId: number; messageId: number }
> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ commentId, messageId }) => {
            const results = await axios.delete(
                `cooperatives/${selectedCoopId}/messages/${messageId}/comments/${commentId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!results.data.success) {
                throw new Error('Deleting chat message returned unsuccessful result');
            }

            return results.data;
        },
        onMutate: ({ messageId, commentId }) => {
            updateMessage(queryClient, selectedCoopId, messageId, (oldData) =>
                isNoticeMessage(oldData)
                    ? JSON.parse(
                          JSON.stringify({
                              ...oldData,
                              comments: oldData.comments.map((item) =>
                                  item.id !== commentId ? item : { ...item, deleted: true },
                              ),
                          }),
                      )
                    : oldData,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['messages'] });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

const useEditMessageComment = (): UseMutationResult<
    { success: true },
    string | Error,
    { commentId: number; messageId: number; data: MutateCommentMessageBody & { removeFiles: number[] } }
> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ commentId, messageId, data }) => {
            const results = await axios.patch(
                `cooperatives/${selectedCoopId}/messages/${messageId}/comments/${commentId}`,
                { ...data, files: data.files.map(getFileObjectFunc(`${Math.random() * 10000}`)) },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!results.data) {
                throw new Error('Editing message returned unsuccessful result');
            }

            return results.data;
        },
        onMutate: ({ messageId, commentId, data }) => {
            updateMessage(queryClient, selectedCoopId, messageId, (oldData) =>
                isNoticeMessage(oldData)
                    ? JSON.parse(
                          JSON.stringify({
                              ...oldData,
                              comments: oldData.comments.map((item) =>
                                  item.id !== commentId
                                      ? item
                                      : {
                                            ...item,
                                            edited: true,
                                            ...data,
                                            files: [
                                                ...item.files.filter((file) => !data.removeFiles.includes(file.id)),
                                                ...data.files.map(GetFileToBeUploadedToApiFile(item.creator)),
                                            ],
                                        },
                              ),
                          }),
                      )
                    : oldData,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['messages'] });
            queryClient.invalidateQueries({
                queryKey: ['message'],
            });
        },
    });
};

export {
    useGetMessages,
    useGetMessage,
    useCreateMessage,
    useEditMessage,
    useDeleteMessage,
    useMarkMessageRead,
    useAddMessageComment,
    useSubscribeToMessage,
    useUnsubscribeToMessage,
    useDeleteMessageComment,
    useEditMessageComment,
    useProlongMessage,
};
