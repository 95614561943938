import Activity from './files/activity.svg';
import ActivityFilled from './files/activityFilled.svg';
import AddImage from './files/addImage.svg';
import Alert from './files/alert.svg';
import AlertCircle from './files/alertCircle.svg';
import AlertTriangle from './files/alertTriangle.svg';
import Area from './files/area.svg';
import AreaUsable from './files/areaUsable.svg';
import Arrow from './files/arrow.svg';
import AttachedFile from './files/attachedFile.svg';
import Attachment from './files/attachment.svg';
import Award from './files/award.svg';
import Bathing from './files/bathing.svg';
import Bedroom from './files/bedroom.svg';
import Bell from './files/bell.svg';
import BellFilled from './files/bellFilled.svg';
import Bluetooth from './files/bluetooth.svg';
import Board from './files/board.svg';
import BoardMembers from './files/boardMembers.svg';
import Bold from './files/bold.svg';
import Booking from './files/booking.svg';
import BookingFilled from './files/bookingFilled.svg';
import BookLibrary from './files/bookLibrary.svg';
import BriefCase from './files/briefCase.svg';
import Bulb from './files/bulb.svg';
import Calendar from './files/calendar.svg';
import CalendarDates from './files/calendarDates.svg';
import CalendarEvent from './files/calendarEvent.svg';
import Call from './files/call.svg';
import Camera from './files/camera.svg';
import Celebrate from './files/celebrate.svg';
import Chat from './files/chat.svg';
import ChatFilled from './files/chatFilled.svg';
import Check from './files/check.svg';
import CheckboxCircle from './files/checkboxCircle.svg';
import CheckCircle from './files/checkCircle.svg';
import Checked from './files/checked.svg';
import Chevron from './files/chevron.svg';
import Click from './files/click.svg';
import ClipboardText from './files/clipboardText.svg';
import ClockCheck from './files/clockCheck.svg';
import ClockCross from './files/clockCross.svg';
import ClockSpinning from './files/clockSpinning.svg';
import Coffee from './files/coffee.svg';
import ColoredFolder from './files/coloredFolder.svg';
import Comment from './files/comment.svg';
import CommentDots from './files/commentDots.svg';
import Comments from './files/comments.svg';
import CommentsDot from './files/commentsDot.svg';
import CommentText from './files/commentText.svg';
import Community from './files/community.svg';
import CopySheets from './files/copySheets.svg';
import Cross from './files/cross.svg';
import Cutlery2 from './files/cutlery2.svg';
import Direction from './files/direction.svg';
import DocFile from './files/docFile.svg';
import DollarCircle from './files/dollarCircle.svg';
import Download from './files/download.svg';
import Dumbbell from './files/dumbbell.svg';
import Edit from './files/edit.svg';
import EditPencil from './files/editPencil.svg';
import Email from './files/email.svg';
import File from './files/file.svg';
import FlagDa from './files/flagDa.svg';
import FlagEn from './files/flagEn.svg';
import FlagNo from './files/flagno.svg';
import FlagSv from './files/flagsv.svg';
import Floor from './files/floor.svg';
import Folder from './files/folder.svg';
import Games from './files/games.svg';
import GlassWine from './files/glassWine.svg';
import Globe from './files/globe.svg';
import Groups from './files/groups.svg';
import Handle from './files/handle.svg';
import Happy from './files/happy.svg';
import History from './files/history.svg';
import Home from './files/home.svg';
import HomeFilled from './files/homeFilled.svg';
import HourGlass from './files/hourGlass.svg';
import Image from './files/image.svg';
import Import from './files/import.svg';
import Info from './files/info.svg';
import InfoFilled from './files/infoFilled.svg';
import Italic from './files/italic.svg';
import Language from './files/language.svg';
import Lifebuoy from './files/lifebuoy.svg';
import Lightning from './files/lightning.svg';
import LightingFilled from './files/lightningFilled.svg';
import Link from './files/link.svg';
import Loader from './files/loader.svg';
import Location from './files/location.svg';
import Lock from './files/lock.svg';
import LockFilled from './files/lockFilled.svg';
import LockFilledOpen from './files/lockFilledOpen.svg';
import LockSlash from './files/lockSlash.svg';
import Mail from './files/mail.svg';
import MapIcon from './files/map.svg';
import Message from './files/message.svg';
import Minus from './files/minus.svg';
import More from './files/more.svg';
import MoreFilled from './files/moreFilled.svg';
import Note from './files/note.svg';
import PaperClip from './files/paperClip.svg';
import PdfFile from './files/pdfFile.svg';
import Phone from './files/phone.svg';
import Pin from './files/pin.svg';
import Pin2 from './files/pin2.svg';
import Plus from './files/plus.svg';
import PlusSquare from './files/plusSquare.svg';
import PlusSquareFilled from './files/plusSquareFilled.svg';
import PptFile from './files/pptFile.svg';
import Privacy from './files/privacy.svg';
import PrivateColoredFolder from './files/privateColoredFolder.svg';
import Question from './files/question.svg';
import QuestionNew from './files/questionNew.svg';
import RightArrow from './files/rightArrow.svg';
import Search from './files/search.svg';
import Send from './files/send.svg';
import Services from './files/services.svg';
import Settings from './files/settings.svg';
import SharedColoredFolder from './files/sharedColoredFolder.svg';
import Sharing from './files/sharing.svg';
import SharingFilled from './files/sharingFilled.svg';
import ShoppingBag from './files/shoppingBag.svg';
import SignIn from './files/signIn.svg';
import Star from './files/star.svg';
import StarFilled from './files/starFilled.svg';
import StarRating from './files/starRating.svg';
import StarRatingFilled from './files/starRatingFilled.svg';
import Stop from './files/stop.svg';
import StrikeThrough from './files/strikethrough.svg';
import Sun from './files/sun.svg';
import Support from './files/support.svg';
import Survey from './files/survey.svg';
import Tag from './files/tag.svg';
import Tags from './files/tags.svg';
import Terms from './files/terms.svg';
import TextListOrdered from './files/textListOrdered.svg';
import TextListUnordered from './files/textListUnordered.svg';
import ThumbsDown from './files/thumbsDown.svg';
import ThumbsUp from './files/thumbsUp.svg';
import Trash from './files/trash.svg';
import Tv from './files/tv.svg';
import TxtFile from './files/txtFile.svg';
import Unchecked from './files/unchecked.svg';
import Underline from './files/underline.svg';
import User from './files/user.svg';
import UsersCircle from './files/userCircle.svg';
import UserFilled from './files/userFilled.svg';
import UserPlus from './files/userPlus.svg';
import Users from './files/users.svg';
import Wrench from './files/wrench.svg';
import XlsFile from './files/xlsFile.svg';
import XmlFile from './files/xmlFile.svg';
import ZipFile from './files/zipFile.svg';

const icons = {
    activity: Activity,
    activityFilled: ActivityFilled,
    award: Award,
    users: Users,
    userCircle: UsersCircle,
    commentText: CommentText,
    sharing: Sharing,
    sharingFilled: SharingFilled,
    booking: Booking,
    chat: Chat,
    chatFilled: ChatFilled,
    comment: Comment,
    commentDots: CommentDots,
    more: More,
    moreFilled: MoreFilled,
    chevron: Chevron,
    bellFilled: BellFilled,
    bookingFilled: BookingFilled,
    userFilled: UserFilled,
    user: User,
    home: Home,
    services: Services,
    board: Board,
    terms: Terms,
    bell: Bell,
    plus: Plus,
    plusSquare: PlusSquare,
    plusSquareFilled: PlusSquareFilled,
    note: Note,
    happy: Happy,
    question: Question,
    file: File,
    alert: Alert,
    alertCircle: AlertCircle,
    comments: Comments,
    globe: Globe,
    minus: Minus,
    attachment: Attachment,
    send: Send,
    attachedFile: AttachedFile,
    call: Call,
    message: Message,
    commentsDot: CommentsDot,
    image: Image,
    location: Location,
    cross: Cross,
    download: Download,
    unchecked: Unchecked,
    checked: Checked,
    addImage: AddImage,
    trash: Trash,
    edit: Edit,
    dollarCircle: DollarCircle,
    clockCheck: ClockCheck,
    clockCross: ClockCross,
    search: Search,
    arrow: Arrow,
    tag: Tag,
    questionNew: QuestionNew,
    wrench: Wrench,
    shoppingBag: ShoppingBag,
    rightArrow: RightArrow,
    infoFilled: InfoFilled,
    info: Info,
    flagSv: FlagSv,
    flagDa: FlagDa,
    flagEn: FlagEn,
    flagNo: FlagNo,
    settings: Settings,
    privacy: Privacy,
    language: Language,
    starFilled: StarFilled,
    star: Star,
    mail: Mail,
    zipFile: ZipFile,
    txtFile: TxtFile,
    xmlFile: XmlFile,
    docFile: DocFile,
    pdfFile: PdfFile,
    pin2: Pin2,
    xlsFile: XlsFile,
    pptFile: PptFile,
    folder: Folder,
    coloredFolder: ColoredFolder,
    floor: Floor,
    areaUsable: AreaUsable,
    area: Area,
    bedroom: Bedroom,
    lock: Lock,
    editPencil: EditPencil,
    clockSpinning: ClockSpinning,
    lockFilled: LockFilled,
    lockFilledOpen: LockFilledOpen,
    lockSlash: LockSlash,
    bluetooth: Bluetooth,
    calendar: Calendar,
    import: Import,
    signIn: SignIn,
    direction: Direction,
    pin: Pin,
    tags: Tags,
    copySheets: CopySheets,
    paperClip: PaperClip,
    bookLibrary: BookLibrary,
    checkCircle: CheckCircle,
    homeFilled: HomeFilled,
    handle: Handle,
    boardMembers: BoardMembers,
    groups: Groups,
    survey: Survey,
    calendarEvent: CalendarEvent,
    community: Community,
    loader: Loader,
    hourGlass: HourGlass,
    stop: Stop,
    celebrate: Celebrate,
    support: Support,
    camera: Camera,
    calendarDates: CalendarDates,
    userPlus: UserPlus,
    check: Check,
    starRating: StarRating,
    starRatingFilled: StarRatingFilled,
    checkboxCircle: CheckboxCircle,
    history: History,
    thumbsUp: ThumbsUp,
    thumbsDown: ThumbsDown,
    click: Click,
    lightning: Lightning,
    lightningFilled: LightingFilled,
    alertTriangle: AlertTriangle,
    briefCase: BriefCase,
    coffee: Coffee,
    cutlery2: Cutlery2,
    clipboardText: ClipboardText,
    glassWine: GlassWine,
    map: MapIcon,
    tv: Tv,
    sun: Sun,
    bulb: Bulb,
    dumbbell: Dumbbell,
    games: Games,
    bathing: Bathing,
    textListUnordered: TextListUnordered,
    textListOrdered: TextListOrdered,
    bold: Bold,
    italic: Italic,
    strikeThrough: StrikeThrough,
    link: Link,
    underline: Underline,
    sharedColoredFolder: SharedColoredFolder,
    privateColoredFolder: PrivateColoredFolder,
    lifebuoy: Lifebuoy,
    phone: Phone,
    email: Email,
};

export default icons;
