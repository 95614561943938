import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Edge } from 'react-native-safe-area-context';
import { useSearchKartverket, OutputAdresse } from '_api/useKartverket';
import { SafeAreaView } from '_dependencies/safeArea';
import { isAndroid, useKeyboardIsVisible } from '_utils';
import useDebounceValue from '_utils/hooks/useDebounceValue';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { useAuthNavigation, useAuthRoute } from 'Auth/AuthNavigation';
import { HeimeText, Icon, Loader, PrimaryButton, SecondaryButton } from 'Components';
import ControlledInput from 'Components/ControlledInput';
import QueryView from 'Components/QueryViews/QueryView';
import AuthNavigationHeader from './common/AuthNavigationHeader';
import BlendedEmptyState from './common/BlendedEmptyState';

const SelectAddress = () => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const [searchTerm, setSearchTerm] = useState('');
    const { params } = useAuthRoute<'SelectAddress' | 'SelectAddressEdit'>();
    const keyboardVisible = useKeyboardIsVisible();
    const { navigate } = useAuthNavigation();
    const debouncedSearchTerm = useDebounceValue(searchTerm, 350);
    const { status, data, error, isLoading, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useSearchKartverket({
            sok: debouncedSearchTerm,
        });
    const [selectedAddress, setSelectedAddress] = useState<ItemType | null>(null);

    const handleSearchChange = (value: string | undefined) => {
        setSearchTerm(value || '');
    };

    const handleNavigate = (overrideSelectedAddress: boolean) => {
        const address =
            selectedAddress && !overrideSelectedAddress
                ? {
                      ...selectedAddress,
                      bruksenhetsnummer: selectedAddress.bruksenhet ?? '',
                      kommunenummer: selectedAddress.kommunenummer ?? '',
                      gardsnummer: selectedAddress.gardsnummer ? selectedAddress.gardsnummer + '' : '',
                      bruksnummer: selectedAddress.bruksnummer ? selectedAddress.bruksnummer + '' : '',
                      adressenavn: selectedAddress.adressenavn ?? '',
                      nummer: selectedAddress.nummer ? selectedAddress.nummer + '' : '',
                      bokstav: selectedAddress.bokstav ?? '',
                      postnummer: selectedAddress.postnummer ?? '',
                      poststed: selectedAddress.poststed ?? '',
                      matrikkel_addresse: selectedAddress.matrikkel_addresse ?? '',
                  }
                : null;
        const initialGuess = address ? null : searchTerm;
        if ('editParams' in params) {
            navigate('RegistrationEdit', { ...params.editParams, editedAddress: address });
        } else {
            navigate('CreateRegistration', { ...params, address, initialGuess });
        }
    };

    const handleNotFoundPress = () => {
        handleNavigate(true);
    };

    type ItemType = OutputAdresse & {
        id: string;
        title: string;
        subtitle: string;
        matrikkel_addresse: string;
        bruksenhet: string | null;
    };
    const renderAddressItem = ({ item, index }: { item: ItemType; index: number }) => (
        <TouchableOpacity
            onPress={() => setSelectedAddress(item)}
            style={{
                borderTopWidth: index !== 0 ? 1 : 0,
                borderColor: theme.lightGrey,
                paddingVertical: screenMargin,
                gap: smallestMargin,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <View style={{ backgroundColor: theme.lightGrey, padding: smallestMargin, borderRadius: 100 }}>
                <Icon name="home" color="main" scale={1} />
            </View>
            <View style={{ flex: 1 }}>
                <HeimeText style={{ fontSize: subtitleFontSize, color: theme.black, fontWeight: 'bold' }}>
                    {item.title}
                </HeimeText>
                <HeimeText variant="subtitle">{item.subtitle}</HeimeText>
                <HeimeText variant="subtitle">{item.matrikkel_addresse}</HeimeText>
            </View>
            {selectedAddress?.id === item.id ? <Icon name="check" color="main" scale={1} style={{}} /> : null}
        </TouchableOpacity>
    );
    const items = useMemo(
        () =>
            data?.pages
                .flatMap((page) => page.adresser ?? [])
                .reduce<Array<ItemType>>((acc, curr) => {
                    if (curr.bruksenhetsnummer && curr.bruksenhetsnummer.length > 0) {
                        const searchWords = debouncedSearchTerm.split(' ');

                        // hitsAll is true if the search term is found in the base fields, meaning we should include all the numbers
                        const hitsAll = searchWords.every(
                            (word) =>
                                curr.adressenavn?.includes(word) ||
                                curr.adressetekstutenadressetilleggsnavn?.includes(word) ||
                                curr.adressetilleggsnavn?.includes(word) ||
                                curr.adressetekstutenadressetilleggsnavn?.includes(word) ||
                                curr.adressetekstutenadressetilleggsnavn?.includes(word) ||
                                curr.postnummer?.includes(word) ||
                                curr.poststed?.includes(word) ||
                                curr.kommunenavn?.includes(word) ||
                                curr.kommunenummer?.includes(word) ||
                                curr.gardsnummer === Number(word) ||
                                curr.bruksnummer === Number(word) ||
                                curr.festenummer === Number(word) ||
                                curr.undernummer === Number(word),
                        );
                        const numbersToUser = hitsAll
                            ? curr.bruksenhetsnummer
                            : curr.bruksenhetsnummer.filter((unit) => searchWords.some((word) => unit.includes(word)));
                        if (numbersToUser.length === 0) {
                            return acc;
                        }

                        return [
                            ...acc,
                            ...numbersToUser.map((unit) => ({
                                title: `${curr.adressetekst} ${unit}`,
                                subtitle: `${curr.postnummer} ${curr.poststed}`,
                                ...curr,
                                matrikkel_addresse: `${curr.kommunenummer}/${curr.gardsnummer}/${curr.bruksnummer}`,
                                id: `${curr.adressetekst} ${curr.nummer}${curr.bokstav} ${unit}`,
                                bruksenhet: unit,
                            })),
                        ];
                    }
                    return [
                        ...acc,
                        {
                            ...curr,
                            title: curr.adressetekst ?? '',
                            subtitle: `${curr.postnummer} ${curr.poststed}`,
                            matrikkel_addresse: `${curr.kommunenummer}/${curr.gardsnummer}/${curr.bruksnummer}/${curr.festenummer}`,
                            id: `${curr.kommunenummer}/${curr.gardsnummer}/${curr.bruksnummer}/${curr.festenummer} ${curr.adressetekst} ${curr.nummer}${curr.bokstav}`,
                            bruksenhet: null,
                        },
                    ];
                }, [])
                .filter(Boolean) ?? [],
        [data?.pages, debouncedSearchTerm],
    );

    return (
        <SafeAreaView
            style={{ flex: 1, backgroundColor: theme.mainBackground }}
            edges={['bottom', 'left', 'right', 'editParams' in params ? null : 'top'].filter(Boolean) as Edge[]}
        >
            <AuthNavigationHeader horizontalMargin />
            <KeyboardAvoidingView behavior={isAndroid() ? 'padding' : 'height'} style={{ flex: 1 }}>
                <HeimeText style={{ marginTop: screenMargin }} variant="title">
                    {t('selectAddress:title')}
                </HeimeText>
                <ControlledInput
                    label={t('selectAddress:search')}
                    autoFocus
                    containerStyle={{ marginHorizontal: screenMargin }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder={t('selectAddress:type_to_search')}
                    rightImage={
                        searchTerm ? (
                            <TouchableOpacity onPress={() => handleSearchChange('')}>
                                <Icon name="cross" color="darkGrey" />
                            </TouchableOpacity>
                        ) : null
                    }
                />
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: smallestMargin,
                                backgroundColor: theme.lightGrey,
                                padding: 10,
                                borderRadius: 20,
                                margin: screenMargin,
                            }}
                        >
                            <HeimeText variant="subtitle" style={{ color: theme.red }}>
                                {t('selectAddress:error_loading_addresses')}
                            </HeimeText>
                        </View>
                        <SecondaryButton
                            style={{ marginHorizontal: screenMargin }}
                            text={t('selectAddress:enter_manually')}
                            onPress={handleNotFoundPress}
                        />
                    </View>
                ) : status === 'pending' ? (
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: screenMargin,
                        }}
                    >
                        <View
                            style={{
                                borderWidth: StyleSheet.hairlineWidth,
                                borderColor: theme.darkGrey,
                                padding: 10,
                                borderRadius: 10,
                                flexDirection: 'row',
                                margin: screenMargin,
                                alignItems: 'center',
                                gap: smallestMargin,
                            }}
                        >
                            <Icon name="info" color="main" scale={1} style={{}} />
                            <HeimeText variant="subtitle" style={{ color: theme.darkGrey, flex: 1 }}>
                                {t('selectAddress:type_instructions')}
                            </HeimeText>
                        </View>
                    </View>
                ) : (
                    <QueryView
                        data={items}
                        renderItem={renderAddressItem}
                        loadMore={hasNextPage ? fetchNextPage : undefined}
                        isLoadingMore={isFetchingNextPage}
                        emptyList={
                            <BlendedEmptyState
                                title={t('selectAddress:not_found')}
                                subtitle={
                                    <>
                                        {t('selectAddress:no_results_found_keep_searching')}
                                        <HeimeText
                                            style={{ textDecorationLine: 'underline', color: theme.main }}
                                            onPress={handleNotFoundPress}
                                            variant="subtitle"
                                        >
                                            {t('selectAddress:enter_manually')}
                                        </HeimeText>
                                    </>
                                }
                            />
                        }
                        isSearching={isFetching}
                        onRefresh={refetch}
                        containerStyle={{ paddingHorizontal: screenMargin, flexGrow: 1 }}
                        keyboardShouldPersistTaps
                    />
                )}
                {keyboardVisible && !selectedAddress ? null : (
                    <PrimaryButton
                        style={{ marginHorizontal: screenMargin, marginBottom: screenMargin }}
                        text={t('selectAddress:continue')}
                        onPress={() => handleNavigate(false)}
                        status={selectedAddress ? null : 'disabled'}
                    />
                )}
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

export default SelectAddress;
