import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useContextMenu } from '_navigator/PlusMenuContext';
import { Theme, useIsCoopAdmin, useThemeStyle } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import HeimeText from 'Components/HeimeText';
import { MessageType } from 'types/message';
import NewPlusItem from './NewPlusItem';
import { Navigate, PlusNavigation } from './types';

const menuItemsConfig = (
    plusNavigation: PlusNavigation,
    navigate: Navigate,
    isCoopAdmin: boolean,
    reportsDisabled: boolean,
) => [
    {
        icon: 'calendarEvent' as const,
        title: 'activityNew' as const,
        onPress: () => plusNavigation('ActivityNew', {}, 'activityNew'),
    },
    {
        icon: 'groups' as const,
        title: 'groupNew' as const,
        onPress: () => plusNavigation('GroupNew', {}, 'groupNew'),
    },
    {
        icon: 'lifebuoy' as const,
        title: 'helpNew' as const,
        onPress: () => plusNavigation('SharingNew', { type: MessageType.Requests }, 'helpNew'),
    },
    {
        icon: 'commentText' as const,
        title: 'noticeNew' as const,
        onPress: () => plusNavigation('SharingNew', { type: MessageType.Requests }, 'noticeNew'),
    },
    ...(isCoopAdmin
        ? [
              {
                  icon: 'infoFilled' as const,
                  title: 'noticeByBoard' as const,
                  onPress: () => plusNavigation('SharingNew', { type: MessageType.ByBoard }, 'noticeByBoard'),
              },
          ]
        : []),
    {
        icon: 'happy' as const,
        title: 'helpfulNeighbour' as const,
        onPress: () => plusNavigation('SharingNew', { type: MessageType.Helpers }, 'helpfulNeighbour'),
    },
    {
        icon: 'tag' as const,
        title: 'sharingNew' as const,
        onPress: () => plusNavigation('SharingNew', { type: MessageType.Products }, 'sharingNew'),
    },
    ...(reportsDisabled
        ? []
        : [
              {
                  icon: 'alert' as const,
                  title: 'reportsNew' as const,
                  onPress: () => plusNavigation('ReportNew', {}, 'reportsNew'),
              },
          ]),
    {
        icon: 'booking' as const,
        title: 'reservations' as const,
        onPress: () => plusNavigation('Reserving', {}, 'reservations'),
    },
    {
        icon: 'comments' as const,
        title: 'chatNew' as const,
        onPress: () => plusNavigation('ChatNewMembers', { type: 'create' }, 'chatNew'),
    },
];

const PlusItemsList = ({
    plusNavigation,
    navigate,
}: {
    plusNavigation: PlusNavigation;
    navigate: Navigate;
}): ReactElement => {
    const isCoopAdmin = useIsCoopAdmin();
    const cooperative = useSelectedCoopItem();
    const plusItems = useContextMenu().highlightedItems;
    const { t } = useTranslation();

    const { highlightedItems, notHighlightedItems } = useMemo(() => {
        const items = menuItemsConfig(plusNavigation, navigate, isCoopAdmin, Boolean(cooperative?.disable_supports));
        const highlightedItemsInner = items
            .filter((item) => plusItems.includes(item.title))
            .sort((a, b) => plusItems.indexOf(a.title) - plusItems.indexOf(b.title));
        const notHighlightedItemsInner = items.filter((item) => !plusItems.includes(item.title));
        return { highlightedItems: highlightedItemsInner, notHighlightedItems: notHighlightedItemsInner };
    }, [cooperative?.disable_supports, isCoopAdmin, navigate, plusItems, plusNavigation]);

    const showTitle = highlightedItems.length > 0 && notHighlightedItems.length > 0;

    return (
        <>
            {showTitle ? <Title>{t('plusMenu:recommended')}</Title> : null}
            {highlightedItems.map((props) => (
                <NewPlusItem key={props.title} {...props} />
            ))}
            {showTitle ? <Title topMargin>{t('plusMenu:other')}</Title> : null}
            {notHighlightedItems.map((props) => (
                <NewPlusItem key={props.title} {...props} />
            ))}
        </>
    );
};

const Title = ({ children, topMargin }: PropsWithChildren<{ topMargin?: boolean }>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <HeimeText style={[themedStyle.text, topMargin ? themedStyle.topMargin : undefined]}>{children}</HeimeText>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        text: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.black,
            marginLeft: screenMargin,
            marginBottom: screenMargin,
        },
        topMargin: {
            marginTop: screenMargin,
        },
    });

export default PlusItemsList;
