import { ReactElement, useMemo, useState } from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { PanGestureHandler, ScrollView } from 'react-native-gesture-handler';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useResetNavigationStack } from '_navigator';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { Theme, WH, WW, isiOS, useThemeStyle } from '_utils';
import { track } from '_utils/Amplitude';
import { CloseButton } from 'Components';
import { SvgBackground, PlusButton, PlusItemsList, navButtons } from './components';
import { offsetFromTop } from './components/constants';
import { PlusNavigation } from './components/types';
import { useDraggable, useTranslatedKeyboardHeight } from './hooks';

const TabBar = ({ state, descriptors, navigation }: BottomTabBarProps): ReactElement => {
    const [isOpen, setOpen] = useState(false);
    const insets = useSafeAreaInsets();
    const themedStyle = useThemeStyle(styles);
    const translateStyle = useTranslatedKeyboardHeight();
    const { navigate } = useAppNavigation();
    const resetNav = useResetNavigationStack();

    const initialHeight = useMemo(() => (insets.bottom ? 50 + insets.bottom : 60), [insets.bottom]);
    const heightScroll = useMemo(() => WH - offsetFromTop - insets.top, [insets.top]);

    const [togglePlusMenu, eventHandler, animatedStyle, scrollRef] = useDraggable({
        initialHeight,
        menuHeight: heightScroll,
        isOpen: isOpen,
        setOpen: setOpen,
    });

    const plusNavigation: PlusNavigation = (screen, props, type) => {
        track('Plus menu item pressed', { type });
        navigate(screen, props);
        togglePlusMenu();
    };

    const navigationButtons = useMemo(() => {
        return navButtons({ state, descriptors, navigation, insets, resetNav });
    }, [state, descriptors, navigation, insets, resetNav]);

    return (
        <Animated.View
            style={[
                themedStyle.container,
                isiOS() && !isOpen ? themedStyle.containerIos : undefined,
                translateStyle,
                animatedStyle,
            ]}
        >
            {isOpen && (
                <Animated.View entering={FadeIn} exiting={FadeOut} style={themedStyle.backdrop}>
                    <TouchableOpacity
                        style={[themedStyle.backdrop, themedStyle.backdropButton]}
                        onPress={() => togglePlusMenu()}
                        activeOpacity={0.6}
                    />
                </Animated.View>
            )}
            <PanGestureHandler onGestureEvent={eventHandler}>
                <Animated.View>
                    <View style={themedStyle.navContainer}>
                        <SvgBackground />
                        <PlusButton onPress={togglePlusMenu} isOpen={isOpen} />
                        {isOpen && <CloseButton onPress={togglePlusMenu} style={themedStyle.closeButton} />}
                        <View style={themedStyle.tabButtonsParent} pointerEvents="box-none">
                            {!isOpen && (
                                <>
                                    <Animated.View
                                        style={themedStyle.tabButtonsView}
                                        entering={FadeIn}
                                        exiting={FadeOut}
                                    >
                                        {navigationButtons[0]}
                                        {navigationButtons[1]}
                                    </Animated.View>
                                    <Animated.View
                                        style={[themedStyle.tabButtonsView, themedStyle.tabButtonsRight]}
                                        entering={FadeIn}
                                        exiting={FadeOut}
                                    >
                                        {navigationButtons[2]}
                                        {navigationButtons[3]}
                                    </Animated.View>
                                </>
                            )}
                        </View>
                    </View>
                    <View style={[themedStyle.menuContainer, { height: heightScroll - initialHeight + insets.bottom }]}>
                        <ScrollView
                            style={[themedStyle.plusMenuScroll, !isOpen ? themedStyle.plusMenuScrollHidden : undefined]}
                            contentContainerStyle={{ paddingBottom: insets.bottom }}
                            ref={scrollRef}
                        >
                            <PlusItemsList plusNavigation={plusNavigation} navigate={navigate} />
                        </ScrollView>
                    </View>
                </Animated.View>
            </PanGestureHandler>
        </Animated.View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            width: WW,
            position: 'absolute',
            bottom: 0,
        },
        containerIos: {
            shadowOffset: {
                width: 0,
                height: -2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
        },
        navContainer: {
            width: '100%',
            position: 'relative',
            marginBottom: -40,
            height: 100,
        },
        menuContainer: {
            width: WW,
            backgroundColor: theme.mainBackground,
            height: 400,
        },
        backdrop: {
            position: 'absolute',
            top: -WH,
            left: 0,
            right: 0,
            bottom: 0,
        },
        backdropButton: { backgroundColor: '#000', opacity: 0.6 },
        plusMenuScroll: {
            height: '100%',
        },
        plusMenuScrollHidden: {
            display: 'none',
        },
        tabButtonsParent: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            // Here because plus button not clickable on web
            zIndex: 1,
        },
        tabButtonsView: {
            display: 'flex',
            flexDirection: 'row',
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: 15,
        },
        tabButtonsRight: {
            justifyContent: 'flex-end',
        },
    });

export default TabBar;
