import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SafeAreaView } from '_dependencies/safeArea';
import { MainHeader } from '_navigator/components';
import ActionBanner from 'Components/ActionBanner';
import ServicesList from './common/ServicesList';
import useServicesSearch from './useServicesSearch';
import { useGetCategories } from '../../_api/useCategories';
import { useGetReservations } from '../../_api/useReservations';
import { useAppNavigation } from '../../_navigator';
import { isReservationOnGoing, Theme, useThemeStyle, WH, WW } from '../../_utils';
import { flattenIniniteResult } from '../../_utils/misc';
import { screenMargin, smallestMargin } from '../../_utils/sizes';
import { BottomSpacer, ExpandableSearchInput, Loader, QueryItemView } from '../../Components';
import HeaderWithNav from '../../Components/HeaderWithNav';

interface ServiceProps {
    route: { params: { showBackHeader: 'true' | 'false' } };
}

const Services = ({ route }: ServiceProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { data: reservations } = useGetReservations();
    const ongoingReservations = useMemo(
        () => flattenIniniteResult(reservations).filter(isReservationOnGoing),
        [reservations],
    );
    const insets = useSafeAreaInsets();

    const {
        data,
        isLoading: isLoadingCategories,
        refetch: refetchCategories,
        isRefetching: refreshingCategories,
        isError,
        error,
    } = useGetCategories();

    if (isError) {
        throw error;
    }

    const handleRefresh = () => {
        refetchCategories();
    };

    const { items, searchText, handleSearchTextChange } = useServicesSearch(data ?? { categories: [], products: [] });

    const reservationsLength = useMemo(
        () =>
            flattenIniniteResult(reservations).filter((reservation) =>
                moment.unix(reservation.end_at).isSameOrAfter(moment()),
            ).length,
        [reservations],
    );

    const showBackHeader = route.params?.showBackHeader === 'true';

    const content = (
        <>
            {reservationsLength > 0 ? (
                <View style={themedStyle.myThingsContainer}>
                    <ActionBanner
                        onPress={() => navigate('ReservationsAll', {})}
                        title={t('services:myReservations')}
                        message={
                            ongoingReservations.length === 1
                                ? t('services:oneOngoing')
                                : ongoingReservations.length > 0
                                  ? `${ongoingReservations.length} ${t('services:ongoingReservations')}`
                                  : reservationsLength === 1
                                    ? t('services:oneReservation')
                                    : `${reservationsLength} ${t('services:reservations')}`
                        }
                        icon="calendar"
                        variant={ongoingReservations.length > 0 ? 'mustard-focus' : 'mustard'}
                    />
                </View>
            ) : undefined}
            <View style={themedStyle.searchContainer}>
                <ExpandableSearchInput
                    value={searchText}
                    onChange={handleSearchTextChange}
                    buttonText={t('global:search')}
                    inputStyle={themedStyle.search}
                />
            </View>
            <ServicesList items={items} />
            <BottomSpacer />
        </>
    );

    if (showBackHeader) {
        return (
            <SafeAreaView style={themedStyle.safeArea} edges={['top', 'left', 'right']}>
                <HeaderWithNav title={t('services:title')} style={themedStyle.title} />
                {isLoadingCategories ? (
                    <Loader />
                ) : (
                    <QueryItemView
                        keyboardShouldPersistTaps="handled"
                        virtualized
                        onRefresh={handleRefresh}
                        isRefreshing={refreshingCategories}
                    >
                        {content}
                    </QueryItemView>
                )}
            </SafeAreaView>
        );
    }

    return (
        <QueryItemView
            style={[themedStyle.container, { paddingTop: insets.top }]}
            keyboardShouldPersistTaps="handled"
            virtualized
            onRefresh={handleRefresh}
            isRefreshing={refreshingCategories}
        >
            <MainHeader />
            {isLoadingCategories ? (
                <View style={{ height: WH * 0.8 }}>
                    <Loader bgColor="shadow" />
                </View>
            ) : (
                content
            )}
        </QueryItemView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        safeArea: { height: '100%', backgroundColor: theme.shadow },
        container: { backgroundColor: theme.shadow, display: 'flex', flexGrow: 1 },
        title: {
            marginBottom: WH * 0.01,
        },
        myThingsContainer: {
            backgroundColor: theme.lightBackground,
            margin: screenMargin,
            marginBottom: 0,
            borderRadius: WW * 0.04,
            overflow: 'hidden',
            borderWidth: 2,
            borderColor: theme.secondaryLight,
        },
        searchContainer: {
            marginTop: smallestMargin,
            marginRight: screenMargin,
            marginLeft: screenMargin,
        },
        search: {
            backgroundColor: theme.mainBackground,
        },
    });

export default Services;
