import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { authorization } from '_api';
import { loggedInState } from '_redux/settings';
import { isAppError, Theme, useThemeStyle, isNetworkError } from '_utils';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { PrimaryButton, SecondaryButton, PinInput } from 'Components';
import BackButton from './BackButton';
import Title from './Title';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

interface EnterPinProps {
    onComplete(result: loggedInState): void;
}

const EnterPin = ({ onComplete }: EnterPinProps): ReactElement => {
    const [errorAttempts, setErrorAttempts] = useState(0);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'EnterPin'>();
    const [status, setStatus] = useState<'errorGeneric' | 'errorPin' | 'loading' | 'errorNoNetwork' | null>(null);
    const [code, setCode] = useState('');

    const handleChangeCode = (newCode: string) => {
        if (status?.startsWith('error')) {
            setStatus(null);
        }
        setCode(newCode);
    };

    const handleSubmit = useCallback(async () => {
        const func = async (retries = 0): Promise<ReturnType<typeof authorization.loginAsync>> => {
            try {
                return await authorization.loginAsync(params.phone, code);
            } catch (ex: unknown) {
                if (isAppError(ex) && isNetworkError(ex)) {
                    if (retries > 2) {
                        throw ex;
                    }
                    return func(retries + 1);
                }
                throw ex;
            }
        };

        try {
            setStatus('loading');
            addBreadcrumb('userAction', 'User entered pin', 'info');
            const result = await func();
            if (result?.data) {
                onComplete({
                    isLoggedIn: true,
                    access_token: result.data.access_token,
                    refresh_token: result.data.refresh_token,
                    expires_in: moment().add({ seconds: result.data.expires_in }).unix(),
                    user_id: null,
                });
            } else {
                setStatus('errorGeneric');
                setCode('');
            }
        } catch (ex: unknown) {
            if (isAppError(ex)) {
                if (isNetworkError(ex)) {
                    setCode('');
                    setStatus('errorNoNetwork');
                    return;
                } else if ((ex?.response?.data as { error?: string })?.error === 'invalid_grant') {
                    if (params.status === 'enterOwnPin') {
                        if (errorAttempts >= 4) {
                            navigation.replace('EnterPin', {
                                phone: params.phone,
                                status: 'ownPinFailed',
                            });
                            return;
                        }
                        setErrorAttempts((currNum) => currNum + 1);
                    }
                    setCode('');
                    setStatus('errorPin');
                    return;
                }
            }
            captureException(ex as Error);
            setCode('');
            setStatus('errorGeneric');
        }
    }, [code, errorAttempts, navigation, onComplete, params.phone, params.status]);

    useEffect(() => {
        if (code.length >= 4 && status !== 'loading') {
            handleSubmit();
        }
    }, [code, handleSubmit, status]);

    const handleSendSms = async () => {
        if (status === 'loading') {
            return;
        }

        if (params.status === 'enterOwnPin') {
            try {
                setStatus('loading');
                const result = await authorization.forgottenAsync(params.phone);
                if (result.data?.success && result.data?.sms_code_send) {
                    navigation.replace('EnterPin', {
                        status: 'enterOTP',
                        phone: params.phone,
                    });
                } else {
                    throw new Error('unexpected response from check endpoint. result.data=' + result.data);
                }
            } catch (ex: unknown) {
                captureException(ex);
                setStatus('errorGeneric');
            }
        } else {
            try {
                setStatus('loading');
                const result = await authorization.resendAsync(params.phone);
                if (result.data?.success && (result.data?.sms_code_send || result.data?.for_testing)) {
                    setStatus(null);
                } else {
                    throw new Error(
                        'unexpected response from check endpoint. result.data=' + JSON.stringify(result.data),
                    );
                }
            } catch (ex: unknown) {
                captureException(ex);
                setStatus('errorGeneric');
            }
        }
    };

    const phrases = ['enterOTP', 'ownPinFailed'].find((el) => el === params.status)
        ? {
              title: t('enterPin:titleSMS'),
              questionText: t('enterPin:subTextSMS'),
              clickableText: t('enterPin:clickableTextSMS'),
          }
        : {
              title: t('enterPin:titlePin'),
              questionText: t('enterPin:subTextPin'),
              clickableText: t('enterPin:sendMeSms'),
          };

    const isError = ['errorGeneric', 'errorPin', 'errorNoNetwork'].find((el) => el === status);

    const showSendSmsButton = errorAttempts >= 3;
    return (
        <>
            <BackButton />
            <Title style={themedStyle.title} text={phrases.title} />
            <View style={themedStyle.textContainer}>
                <PinInput
                    onCodeChange={handleChangeCode}
                    code={code}
                    status={isError ? 'error' : (status as 'loading' | null)}
                />
                {showSendSmsButton ? null : (
                    <View style={themedStyle.messageContainer}>
                        <Text style={themedStyle.questionText}>{phrases.questionText}</Text>
                        <TouchableOpacity>
                            <Text onPress={handleSendSms} style={themedStyle.clickableText}>
                                {phrases.clickableText}
                            </Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
            <View style={themedStyle.bottomContainer}>
                {params.status === 'ownPinFailed' ? (
                    <Text style={themedStyle.errorText}>{t('enterPin:wrongCodeSentSMS')}</Text>
                ) : null}
                {isError ? (
                    <Text style={themedStyle.errorText}>
                        {status === 'errorGeneric'
                            ? t('enterPin:error')
                            : status === 'errorPin'
                              ? t('enterPin:wrongCode')
                              : t('enterPin:noNetwork')}
                    </Text>
                ) : null}
                {showSendSmsButton ? (
                    <SecondaryButton
                        status={status === 'loading' ? 'disabled' : null}
                        onPress={() => handleSendSms()}
                        text={t('enterPin:sendMeSms').toUpperCase()}
                        style={themedStyle.subButton}
                    />
                ) : null}
                <PrimaryButton
                    status={status === 'loading' ? 'loading' : !code ? 'disabled' : null}
                    onPress={() => handleSubmit()}
                    text={t('enterPin:button').toUpperCase()}
                />
            </View>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24,
            marginBottom: 36,
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            flexShrink: 1,
        },
        textContainer: {
            flexShrink: 0,
            flex: 1,
        },
        bottomContainer: {
            flexShrink: 0,
        },
        questionText: {
            color: theme.darkGray,
        },
        clickableText: {
            color: theme.main,
            marginLeft: 8,
        },
        errorText: {
            color: theme.error,
            marginBottom: 12,
            textAlign: 'center',
        },
        subButton: {
            marginBottom: 12,
        },
    });

export default EnterPin;
