import Container from './Container';
import CreateRegistration from './CreateRegistration';
import EnterPhone from './EnterPhone';
import EnterPin from './EnterPin';
import LoggedInGate from './LoggedInGate';
import RegistrationSelected from './RegistrationSelected';
import SelectNumber from './SelectNumber';
import SelectRole from './SelectRole';
import Title from './Title';

export {
    EnterPhone,
    EnterPin,
    Title,
    Container,
    LoggedInGate,
    SelectNumber,
    SelectRole,
    CreateRegistration,
    RegistrationSelected,
};
