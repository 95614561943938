import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import i18next from 'i18next';
import moment from 'moment-timezone';
import * as RNLocalize from 'react-native-localize';
import da from './da.json';
import en from './en.json';
import no from './no.json';
import sv from './sv.json';
import { selectLanguage } from '../_redux/selectedLanguage';
import { useAppDispatch, useGlobalState } from '../_utils';
import { setBaseUrl } from '../_utils/Axios';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/da';
// This has to be imported last, or the tests
import 'moment/locale/en-gb';
import './initializeLocalization';

const AVAILABLE_LANGUAGES = {
    no: {
        file: no,
        momentLocale: 'nb',
        flag: 'flagNo',
        title: 'global:noLang',
        langAliases: ['nb', 'no'],
    },
    en: {
        file: en,
        momentLocale: 'en-gb',
        flag: 'flagEn',
        title: 'global:enLang',
        langAliases: ['en', 'en-gb', 'en-AU', 'en-CA', 'en-IN', 'en-IE', 'en-NZ', 'en-SG', 'en-ZA'],
    },
    da: {
        file: da,
        momentLocale: 'da',
        flag: 'flagDa',
        title: 'global:daLang',
        langAliases: ['da', 'dk'],
    },
    sv: {
        file: sv,
        momentLocale: 'sv',
        flag: 'flagSv',
        title: 'global:svLang',
        langAliases: ['sv'],
    },
} as const;

const setLanguage = (lang: keyof typeof AVAILABLE_LANGUAGES) => {
    moment.locale(AVAILABLE_LANGUAGES[lang].momentLocale);
    i18next.changeLanguage(lang);
    setBaseUrl(lang);
};

const LanguageProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (selectedLanguage) {
            setLanguage(selectedLanguage);
        } else {
            const suggestedLang = RNLocalize.findBestLanguageTag(
                Object.values(AVAILABLE_LANGUAGES).reduce((acc, curr) => [...acc, ...curr.langAliases], [] as string[]),
            )?.languageTag;

            const langKey: keyof typeof AVAILABLE_LANGUAGES = (Object.entries(AVAILABLE_LANGUAGES).find(([_, value]) =>
                value.langAliases.includes(suggestedLang as never),
            )?.[0] ?? 'no') as keyof typeof AVAILABLE_LANGUAGES;

            setLanguage(langKey);
            dispatch(selectLanguage(langKey));
        }
    }, [dispatch, selectedLanguage]);

    return <>{children}</>;
};

export { LanguageProvider, AVAILABLE_LANGUAGES };
