import { useInfiniteQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { z, ZodType } from 'zod';
import safeParse from '../_utils/safeParse';

/**
 * Parameters for searching addresses in Kartverket's API.
 * At least one search parameter must be used. For general searches, it's recommended to use the "sok" parameter,
 * and then narrow down results using other parameters if needed. Example: sok?sok=munkegata&kommunenummer=5001
 */
type KartverketSearchParams = {
    /** General address search across most fields. Wildcard search with "*" is supported (note: spaces cannot be replaced with wildcards) */
    sok?: string;
    /** Performs a fuzzy search that finds similar addresses. Only modifies the "sok" field. Not compatible with wildcards */
    fuzzy?: boolean;
    /** Modifies the "sok" field, default is "AND". Choose if search should require all parameters or just one to match */
    sokemodus?: SokGetSokemodusEnum;
    /** Name of street, road, path, square or area registered in the land registry */
    adressenavn?: string;
    /** Official address as text string (e.g. "Ven, Sørumvegen 45"), without unit number for apartments */
    adressetekst?: string;
    /** Inherited property name, name of institution/building or hamlet used as part of official address */
    adressetilleggsnavn?: string;
    /** Number uniquely identifying addressable streets, roads, paths, squares within municipality */
    adressekode?: number;
    /** Part of address number that is a number, e.g. "23" from "23B" */
    nummer?: number;
    /** Letter part of address number, e.g. "B" from "23B". Empty string searches for addresses without letters */
    bokstav?: string;
    /** Municipality number according to Statistics Norway. Must be 4 digits, e.g. "0301" */
    kommunenummer?: string;
    /** Name (Norwegian) of municipality */
    kommunenavn?: string;
    /** Farm number part of cadastral address */
    gardsnummer?: number;
    /** Property number part of cadastral address */
    bruksnummer?: number;
    /** Lease number part of cadastral address */
    festenummer?: number;
    /** Sequential numbering of cadastral addresses with same farm, property and lease number */
    undernummer?: number;
    /** Unit number for e.g. apartments. First letter and two digits indicate floor, last two digits indicate unit number */
    bruksenhetsnummer?: string;
    /** Filter by address type: street address or cadastral address */
    objtype?: SokGetObjtypeEnum;
    /** Post office name according to Postal Service lists */
    poststed?: string;
    /** Postal code. Must be 4 digits. PO box numbers not included */
    postnummer?: string;
    /** Comma-separated list of objects to filter out. Use "." notation for sub-objects */
    filtrer?: string;
    /** Coordinate system for address geometry, specified as SRID. Default is 4258 */
    utkoordsys?: number;
    /** Number of results per page */
    treffPerSide?: number;
    /** Guarantees returned data is ASCII-compatible */
    asciiKompatibel?: boolean;
};

const useSearchKartverket = (params: KartverketSearchParams) => {
    const { treffPerSide = 30, asciiKompatibel = false, sokemodus = 'AND', ...restParams } = params;
    const enabled = Object.values(restParams).some(Boolean);

    return useInfiniteQuery({
        queryKey: ['kartverket', 'search', { ...params }],
        queryFn: async ({ pageParam = 0 }) => {
            const queryParams = new URLSearchParams(
                Object.entries({
                    ...restParams,
                    sokemodus,
                    fuzzy: params.fuzzy?.toString(),
                    adressekode: params.adressekode?.toString(),
                    nummer: params.nummer?.toString(),
                    gardsnummer: params.gardsnummer?.toString(),
                    bruksnummer: params.bruksnummer?.toString(),
                    festenummer: params.festenummer?.toString(),
                    undernummer: params.undernummer?.toString(),
                    utkoordsys: params.utkoordsys?.toString(),
                    treffPerSide: treffPerSide.toString(),
                    asciiKompatibel: asciiKompatibel.toString(),
                    side: pageParam.toString(),
                })
                    .filter(([_, value]) => value !== undefined)
                    .reduce(
                        (acc, [key, value]) => {
                            if (key !== undefined && value !== undefined) {
                                acc[key] = value;
                            }
                            return acc;
                        },
                        {} as Record<string, string>,
                    ),
            );
            const response = await axios.get(`https://ws.geonorge.no/adresser/v1/sok?${queryParams}`, {
                disableDefaultHeaders: true,
            } as AxiosRequestConfig);
            return safeParse(response.data, OutputAdresseListSchema);
        },
        getNextPageParam: (lastPage) => {
            const currentPage = lastPage.metadata?.side ?? 0;
            const totalPages = Math.ceil((lastPage.metadata?.totaltAntallTreff ?? 0) / treffPerSide);
            return currentPage < totalPages - 1 ? currentPage + 1 : undefined;
        },
        gcTime: 1000 * 60 * 60 * 24,
        staleTime: 1000 * 60 * 2.5,
        initialPageParam: 0,
        enabled: !!enabled,
    });
};

/**
 *
 *
 * @interface GeomPoint
 */
interface GeomPoint {
    /**
     * Koordinatsystem til punktet. Angitt vha EPSG-kode.
     * @type {string}
     * @memberof GeomPoint
     */
    epsg: string | null;
    /**
     * Geografiske latitude/breddegrad/nordlige koordinater, med mindre annet er spesifisert.
     * @type {number}
     * @memberof GeomPoint
     */
    lat: number;
    /**
     * Geografiske longitude/lengdegrad/østlige koordinater, med mindre annet er spesifisert.
     * @type {number}
     * @memberof GeomPoint
     */
    lon: number;
}
/**
 *
 *
 * @interface HitMetadata
 */
interface HitMetadata {
    /**
     * Antall treff per side.
     * @type {number}
     * @memberof HitMetadata
     */
    treffPerSide: number | null;
    /**
     * Sidenummeret som vises. Første side = 0
     * @type {number}
     * @memberof HitMetadata
     */
    side: number | null;
    /**
     * Antall treff som søket returnerte.
     * @type {number}
     * @memberof HitMetadata
     */
    totaltAntallTreff: number | null;
    /**
     * Søkestrengen som ble sendt inn til API-et.
     * @type {string}
     * @memberof HitMetadata
     */
    sokeStreng: string | null;
    /**
     * Garanterer at dataene som returneres er ascii-kompatible.
     * @type {boolean}
     * @memberof HitMetadata
     */
    asciiKompatibel: boolean | null;
    /**
     * Hvilket resultatnummer det første objektet du ser har.
     * @type {number}
     * @memberof HitMetadata
     */
    viserFra: number | null;
    /**
     * Hvilket resultatnummer det siste objektet du ser har.
     * @type {number}
     * @memberof HitMetadata
     */
    viserTil: number | null;
}
/**
 *
 *
 * @interface OutputAdresse
 */
export interface OutputAdresse {
    /**
     * Navn på gate, veg, sti, plass eller område som er ført i matrikkelen (eksempel Sørumvegen).
     * @type {string}
     * @memberof OutputAdresse
     */
    adressenavn: string | null;
    /**
     * Del av offisiell adresse, men uten bruksenhetsnummer som ligger til bruksenheter/boliger (ligger her som egenskap til vegadressen) Eksempel: \"Storgata 2B\" eller \"123/4-2\" Der det i tillegg er adressetilleggsnavn: \"Haugen, Storgata 2B\" eller \"Midtgard, 123/4-2\"
     * @type {string}
     * @memberof OutputAdresse
     */
    adressetekst: string | null;
    /**
     * Nedarvet bruksnavn, navn på en institusjon eller bygning eller grend brukt som del av den offisielle adressen
     * @type {string}
     * @memberof OutputAdresse
     */
    adressetilleggsnavn: string | null;
    /**
     * Nummer som entydig identifiserer adresserbare gater, veger, stier, plasser og områder som er ført i matrikkelen innen kommunen
     * @type {number}
     * @memberof OutputAdresse
     */
    adressekode: number | null;
    /**
     * Del av adressenummer (husnummer) som er et nummer og eventuelt en bokstav, f.eks 23B
     * @type {number}
     * @memberof OutputAdresse
     */
    nummer: number | null;
    /**
     * Del av adressenummer (husnummer) som er et nummer og eventuelt en bokstav, f.eks 23B
     * @type {string}
     * @memberof OutputAdresse
     */
    bokstav: string | null;
    /**
     * Nummerering av kommunen i henhold til Statistisk sentralbyrå sin offisielle liste. Tekstverdi som må bestå av 4 tall. 0301 er for eksempel gyldig, mens 301 er ikke gyldig.
     * @type {string}
     * @memberof OutputAdresse
     */
    kommunenummer: string | null;
    /**
     * Navn (norsk) på en kommune
     * @type {string}
     * @memberof OutputAdresse
     */
    kommunenavn: string | null;
    /**
     * Del av en matrikkeladresse der vegadresse ikke er innført, - eller vegadressens knytning til matrikkelenhet (grunneiendom eller feste, - gir her ikke knyting mot seksjon)
     * @type {number}
     * @memberof OutputAdresse
     */
    gardsnummer: number | null;
    /**
     * Del av en matrikkeladresse der vegadresse ikke er innført, - eller vegadressens knytning til matrikkelenhet (grunneiendom eller feste, - gir her ikke knyting mot seksjon)
     * @type {number}
     * @memberof OutputAdresse
     */
    bruksnummer: number | null;
    /**
     * Del av en matrikkeladresse der vegadresse ikke er innført, - eller vegadressens knytning til matrikkelenhet (grunneiendom eller feste, - gir her ikke knytning mot seksjon)
     * @type {number}
     * @memberof OutputAdresse
     */
    festenummer: number | null;
    /**
     * Fortløpende nummerering av matrikkeladresser med samme gårds-, bruks- og festenummer.
     * @type {number}
     * @memberof OutputAdresse
     */
    undernummer: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof OutputAdresse
     */
    bruksenhetsnummer: Array<string> | null;
    /**
     * Vegadresse er offisiell adresse i form av et adressenavn og et adressenummer (Storgata 10). Der kommunen ikke har gått over til vegadresser, vil det finnes matrikkeladresse på formen: gårdsnummer/ bruksnummer/ev festenummer-ev undernummer (10/2) Begge adressetypene kan ha bruksenhetsnummer (leiligheter) og adressetilleggsnavn. Begge adressetypene vises som standard, hvis man kun ønsker å se en av de kan man spesifisere adressetypen via dette parameteret.
     * @type {string}
     * @memberof OutputAdresse
     */
    objtype: OutputAdresseObjtypeEnum | null;
    /**
     * Navn på poststed i henhold til Postens egne lister
     * @type {string}
     * @memberof OutputAdresse
     */
    poststed: string | null;
    /**
     * Unik identifikasjon av et postnummerområde. Tekstverdi som må bestå av 4 tall. 0340 er for eksempel gyldig, mens 340 er ikke gyldig. Postnummer som identifiserer postboksanlegg er ikke med og vil ikke gi treff.
     * @type {string}
     * @memberof OutputAdresse
     */
    postnummer: string | null;
    /**
     * Del av offisiell adresse, men uten bruksenhetsnummer som ligger til bruksenheter/boliger og adressetilleggsnavn Eksempel: \"Storgata 2B\" eller \"123/4-2\"
     * @type {string}
     * @memberof OutputAdresse
     */
    adressetekstutenadressetilleggsnavn: string | null;
    /**
     * Angivelse om stedfestingen (koordinatene) er kontrollert og funnet i orden (verifisert)
     * @type {boolean}
     * @memberof OutputAdresse
     */
    stedfestingverifisert: boolean | null;
    /**
     *
     * @type {GeomPoint}
     * @memberof OutputAdresse
     */
    representasjonspunkt: GeomPoint | null;
    /**
     * Dato for siste endring på objektdataene
     * @type {string}
     * @memberof OutputAdresse
     */
    oppdateringsdato: string | null;
}

const OutputAdresseObjtypeEnum = {
    Vegadresse: 'Vegadresse',
    Matrikkeladresse: 'Matrikkeladresse',
} as const;

type OutputAdresseObjtypeEnum = (typeof OutputAdresseObjtypeEnum)[keyof typeof OutputAdresseObjtypeEnum];

/**
 *
 *
 * @interface OutputAdresseList
 */
interface OutputAdresseList {
    /**
     *
     * @type {HitMetadata}
     * @memberof OutputAdresseList
     */
    metadata: HitMetadata | null;
    /**
     *
     * @type {Array<OutputAdresse>}
     * @memberof OutputAdresseList
     */
    adresser: Array<OutputAdresse>;
}

/**
 *
 */
const SokGetSokemodusEnum = {
    And: 'AND',
    Or: 'OR',
} as const;
type SokGetSokemodusEnum = (typeof SokGetSokemodusEnum)[keyof typeof SokGetSokemodusEnum];
/**
 *
 */
const SokGetObjtypeEnum = {
    Vegadresse: 'Vegadresse',
    Matrikkeladresse: 'Matrikkeladresse',
} as const;
type SokGetObjtypeEnum = (typeof SokGetObjtypeEnum)[keyof typeof SokGetObjtypeEnum];

// Define Zod schema for OutputAdresseList
const GeomPointSchema = z.object({
    epsg: z.string().nullable(),
    lat: z.number(),
    lon: z.number(),
});

const HitMetadataSchema = z.object({
    treffPerSide: z.number().nullable(),
    side: z.number().nullable(),
    totaltAntallTreff: z.number().nullable(),
    sokeStreng: z.string().nullable(),
    asciiKompatibel: z.boolean().nullable(),
    viserFra: z.number().nullable(),
    viserTil: z.number().nullable(),
});

const OutputAdresseSchema = z.object({
    adressenavn: z.string().nullable(),
    adressetekst: z.string().nullable(),
    adressetilleggsnavn: z.string().nullable(),
    adressekode: z.number().nullable(),
    nummer: z.number().nullable(),
    bokstav: z.string().nullable(),
    kommunenummer: z.string().nullable(),
    kommunenavn: z.string().nullable(),
    gardsnummer: z.number().nullable(),
    bruksnummer: z.number().nullable(),
    festenummer: z.number().nullable(),
    undernummer: z.number().nullable(),
    bruksenhetsnummer: z.array(z.string()).nullable(),
    objtype: z.enum(['Vegadresse', 'Matrikkeladresse']).nullable(),
    poststed: z.string().nullable(),
    postnummer: z.string().nullable(),
    adressetekstutenadressetilleggsnavn: z.string().nullable(),
    stedfestingverifisert: z.boolean().nullable(),
    representasjonspunkt: GeomPointSchema.nullable(),
    oppdateringsdato: z.string().nullable(),
});

const OutputAdresseListSchema: ZodType<OutputAdresseList> = z.object({
    metadata: HitMetadataSchema,
    adresser: z.array(OutputAdresseSchema),
});

export { useSearchKartverket };
