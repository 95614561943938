import { useEffect, useState } from 'react';
import { EditorBridge, RichText, useBridgeState } from '@10play/tentap-editor';
import { View } from 'react-native';
import { WebViewRenderProcessGoneEvent } from 'react-native-webview/lib/WebViewTypes';
import { isAndroid } from '_utils/isPlatform';
import { addBreadcrumb } from '_utils/Sentry';
import { useThemeContext } from '_utils/themeContext';

const BaseRichText = ({
    editor,
    fontSize,
    containerStyle,
    style,
}: {
    editor: EditorBridge;
    fontSize: number;
    containerStyle: string;
    style?: string;
}) => {
    const { theme } = useThemeContext();
    const [isCrashed, setIsCrashed] = useState(false);

    const baseStyle = `
        background-color: ${theme.mainBackground};
        font-size: ${fontSize}px;
    `;

    const bridgeState = useBridgeState(editor);

    useEffect(() => {
        if (bridgeState.isReady) {
            editor.injectCSS(`${customFont}
                body, #root, .tiptap.ProseMirror {
                    color: ${theme.black};
                    caret-color: ${theme.main};
                    ${style ?? baseStyle}
                }
    
                .is-editor-empty:first-child::before {
                    color: ${theme.secondaryLight};
                }
    
                .tiptap.ProseMirror {
                    ${containerStyle}
                }
                
                a {
                    color: ${theme.hyperText};
                }
                `);
        }
    }, [
        bridgeState.isReady,
        baseStyle,
        containerStyle,
        editor,
        fontSize,
        style,
        theme.black,
        theme.hyperText,
        theme.lightBackground,
        theme.main,
        theme.mainBackground,
        theme.secondaryLight,
    ]);

    useEffect(() => {
        if (bridgeState.isReady) {
            editor.injectJS(disableZoomJavascript);
        }
        // We only need to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bridgeState.isReady]);

    useEffect(() => {
        if (!bridgeState.isReady) {
            const timeout = setInterval(() => {
                addBreadcrumb('richtext', 'RichText never got ready so we reload!');
                setIsCrashed(!isCrashed);
            }, 10000); // 1 or 10 seconds
            return () => clearInterval(timeout);
        }
    }, [bridgeState.isReady, isCrashed]);

    return (
        <>
            {isAndroid() && !bridgeState.isReady ? (
                <View
                    style={{
                        backgroundColor: theme.lightGrey,
                        opacity: 0.7,
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                />
            ) : null}
            <RichText
                key={isCrashed ? 'crashed' : 'notCrashed'}
                onRenderProcessGone={(event: WebViewRenderProcessGoneEvent) => {
                    addBreadcrumb(
                        'richtext',
                        `renderProcessGone:didCrash: ${event.nativeEvent.didCrash ? 'true' : 'false'}`,
                    );
                    setIsCrashed(event.nativeEvent.didCrash);
                }}
                scrollEnabled={false}
                editor={editor}
                webviewDebuggingEnabled={__DEV__}
            />
        </>
    );
};

const customFont = `
@font-face {
    font-family: CenturyGothic-Bold;
    src: url('https://app.heime.com/app/CenturyGothic-Bold.ttf');
}

@font-face {
    font-family: CenturyGothic;
    src: url('https://app.heime.com/app/CenturyGothic.ttf');
}

* {
    font-family: 'Century Gothic', sans-serif;
    overflow:hidden !important;
}

ul, ol, li, li p:first-child {
    overflow: visible !important;
}

.is-editor-empty {
    margin: 0;
    min-height: 40px;
}

#root div.dynamic-height .ProseMirror {
    padding-bottom: 0 !important;
}

p:first-child {
    margin-top: 0 !important;
}
`;

const disableZoomJavascript = `
    const existingMeta = document.querySelector('meta[name="viewport"]');
    if (existingMeta) {
        existingMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
    } else {
        const meta = document.createElement('meta');
        meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
        meta.setAttribute('name', 'viewport');
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
`;

export default BaseRichText;
