import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import da from './da.json';
import en from './en.json';
import no from './no.json';
import sv from './sv.json';
// We do this in its own file so we can reuse it in tests

i18next.use(initReactI18next).init({
    resources: {
        en,
        no,
        da,
        sv,
    },
    lng: typeof jest !== 'undefined' ? 'ci' : undefined,
    react: {
        useSuspense: false,
    },
    interpolation: {
        escapeValue: false,
    },
    returnNull: false,
    compatibilityJSON: 'v3',
});
