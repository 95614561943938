import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMarkMessageRead, messageMutationKey } from '../../_api/useMessages';
import { useGetNotifications, useMarkNotificationSeen, notificationMutationKey } from '../../_api/useNotifications';
import { cancelNotification, getDisplayedNotifications } from '../../_dependencies/notifee';
import { flattenIniniteResult } from '../../_utils/misc';

const useReadMessage = (): ((messageId: number[], isRead: boolean) => void) => {
    const queryClient = useQueryClient();
    const { mutate: markMessageRead, isPending: isLoading } = useMarkMessageRead();
    const { data: userNotifications } = useGetNotifications();
    const { mutate: markNotificationSeen } = useMarkNotificationSeen();

    return useCallback(
        (messageId: number[], isRead: boolean) => {
            if (
                queryClient.isMutating({ mutationKey: [messageMutationKey] }) ||
                queryClient.isMutating({ mutationKey: [notificationMutationKey] })
            ) {
                return;
            }

            if (!isRead && !isLoading) {
                markMessageRead(messageId);
            }

            const relatedNotifications = flattenIniniteResult(userNotifications)
                .filter(
                    (notification) =>
                        (notification.seen === false &&
                            notification.type === 'NewMessageComment' &&
                            messageId.includes(notification.messageId)) ||
                        (notification.type === 'MessageCreated' && messageId.includes(notification.notice_id)),
                )
                // .map((not) => console.log(not) || not)
                .map(({ id }) => id);

            if (relatedNotifications.length) {
                markNotificationSeen(relatedNotifications);
            }

            const stringMessageId = messageId.map((id) => `${id}`);
            getDisplayedNotifications().then((notifications) =>
                notifications
                    .filter(({ notification }) => {
                        if (
                            !notification.data ||
                            !notification.data.type ||
                            typeof notification.data.type !== 'string'
                        ) {
                            return false;
                        }
                        if (notification.data.type === 'NewMessageComment' && notification.data.messageId) {
                            return stringMessageId.includes(notification.data.messageId + '');
                        }
                        if (notification.data.type === 'MessageCreated' && notification.data.notice_id) {
                            return stringMessageId.includes(notification.data.notice_id + '');
                        }
                        return false;
                    })
                    .forEach((notification) => cancelNotification(notification.id ?? '')),
            );
        },
        [markMessageRead, markNotificationSeen, queryClient, userNotifications, isLoading],
    );
};

export default useReadMessage;
