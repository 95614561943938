import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { isWeb, WW } from '_utils';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { PrimaryButton, HeimeText, Icon, SecondaryButton, InfoMessage } from 'Components';
import AuthNavigationHeader from './common/AuthNavigationHeader';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const roles = [
    { id: 'owner', icon: 'home' },
    { id: 'renting', icon: 'tag' },
    { id: 'livesWith', icon: 'groups' },
    { id: 'other', icon: 'moreFilled' },
] as const;

const SelectRole = (): ReactElement => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'SelectRole'>();
    const [selectedRole, setSelectedRole] = useState<(typeof roles)[number]['id'] | null>(null);

    const handleConfirm = () => {
        if (selectedRole === 'owner') {
            track('User selected owner role in select role');
            navigation.navigate('SelectAddressCountry', { ...params });
        } else {
            track('User selected non-owner role in select role');
            navigation.navigate('NonOwnerInstructions');
        }
    };

    const handleNotResident = () => {
        track('User selected do not live in Heime project in select role');
        navigation.navigate('LeadForm', { ...params });
    };

    const containerPadding = screenMargin * 2;
    const gapBetweenItems = screenMargin;
    // On web, we need to subtract the scrollbar width
    const itemWidth = (WW - containerPadding - gapBetweenItems - (isWeb() ? screenMargin : 0)) / 2;

    return (
        <>
            <AuthNavigationHeader />

            <View style={{ marginBottom: screenMargin, gap: smallestMargin, marginTop: screenMargin }}>
                <HeimeText variant="title">{t('selectRole:title')}</HeimeText>
                <HeimeText variant="subtitle" style={{ alignSelf: 'center' }}>
                    {t('selectRole:subTitle')}
                </HeimeText>
            </View>
            <View
                style={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: gapBetweenItems,
                    justifyContent: 'space-between',
                    flexGrow: 1,
                }}
            >
                {roles.map((role) => (
                    <TouchableOpacity
                        key={role.id}
                        style={{
                            height: itemWidth * 0.8,
                            width: itemWidth,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: smallestMargin,
                            borderWidth: 1,
                            borderRadius: screenMargin,
                            borderColor: selectedRole === role.id ? theme.main : theme.secondary,
                            backgroundColor: selectedRole === role.id ? theme.lightGreen : undefined,
                        }}
                        onPress={() => setSelectedRole(role.id)}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: smallestMargin,
                                borderRadius: WW / 2,
                                borderWidth: 1,
                                borderColor: theme.main,
                                padding: screenMargin * 1.5,
                            }}
                        >
                            <Icon name={role.icon} scale={1.8} color={'main'} />
                        </View>
                        <HeimeText style={{ fontSize: subtitleFontSize, color: theme.black, fontWeight: 'bold' }}>
                            {t(`selectRole:${role.id}`)}
                        </HeimeText>
                    </TouchableOpacity>
                ))}
            </View>
            {selectedRole === 'owner' ? (
                <Animated.View
                    style={{ flexGrow: 1, justifyContent: 'center' }}
                    entering={FadeIn.duration(300)}
                    exiting={FadeOut.duration(150)}
                >
                    <InfoMessage message={t('selectRole:owner_info_title')} />
                </Animated.View>
            ) : null}

            <View style={{ gap: smallestMargin }}>
                <PrimaryButton
                    status={selectedRole ? null : 'disabled'}
                    onPress={handleConfirm}
                    text={t('selectRole:continue')}
                />
                <SecondaryButton onPress={handleNotResident} text={t('selectRole:not_resident')} />
            </View>
        </>
    );
};

export default SelectRole;
