import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FlashList } from '@shopify/flash-list';
import { getCountries } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, StyleSheet, KeyboardAvoidingView } from 'react-native';
import FastImage from '_dependencies/fastImage';
import { SafeAreaView } from '_dependencies/safeArea';
import { useGlobalState, useKeyboardIsVisible } from '_utils';
import { track } from '_utils/Amplitude';
import useDebounceValue from '_utils/hooks/useDebounceValue';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { useAuthNavigation, useAuthRoute } from 'Auth/AuthNavigation';
import { HeimeText, Icon, PrimaryButton } from 'Components';
import ControlledInput from 'Components/ControlledInput';
import AuthNavigationHeader from './common/AuthNavigationHeader';
import BlendedEmptyState from './common/BlendedEmptyState';

// Map of country codes to our custom flag icons
const CUSTOM_FLAG_ICONS: Record<string, ComponentProps<typeof Icon>['name']> = {
    NO: 'flagNo',
    DK: 'flagDa',
    SE: 'flagSv',
    GB: 'flagEn',
};

// Priority countries to show at the top
const PRIORITY_COUNTRIES = ['NO', 'DK', 'SE', 'GB'];

// Define a type for our country items
type CountryItemType = {
    countryCode: string;
    title: string;
    icon?: ComponentProps<typeof Icon>['name'];
    onPress: () => void;
    selected: boolean;
};

const SelectAddressCountry = () => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const { params } = useAuthRoute<'SelectAddressCountry'>();
    const { navigate } = useAuthNavigation();
    const keyboardVisible = useKeyboardIsVisible();
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const debouncedSearchQuery = useDebounceValue(searchQuery, 300);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

    // Extract the onPress handler outside of useMemo to prevent recreating functions on every render
    const handleCountryPress = useCallback((countryCode: string) => {
        setSelectedCountry(countryCode);
    }, []);

    const countries = useMemo(() => {
        // Map language codes to country codes
        const languageToCountry: Record<string, string> = {
            no: 'NO',
            da: 'DK',
            sv: 'SE',
            en: 'GB',
        };

        const countryCodes = getCountries();
        const countryItems = countryCodes.map((countryCode) => {
            return {
                countryCode,
                title: t(`countryCode:${countryCode.toLowerCase() as Lowercase<typeof countryCode>}`),
                icon: CUSTOM_FLAG_ICONS[countryCode],
                // Use the stable callback reference instead of creating a new function
                onPress: () => handleCountryPress(countryCode),
                selected: selectedCountry === countryCode,
            };
        });

        // Sort countries: first by priority, then alphabetically
        return countryItems.sort((a, b) => {
            // If the current language's country is in the list, put it at the top
            const currentLanguageCountry = languageToCountry[selectedLanguage ?? 'no'];
            if (a.countryCode === currentLanguageCountry) {
                return -1;
            }
            if (b.countryCode === currentLanguageCountry) {
                return 1;
            }

            // Then sort by priority countries
            const aPriority = PRIORITY_COUNTRIES.indexOf(a.countryCode);
            const bPriority = PRIORITY_COUNTRIES.indexOf(b.countryCode);

            if (aPriority !== -1 && bPriority === -1) {
                return -1;
            }
            if (aPriority === -1 && bPriority !== -1) {
                return 1;
            }
            if (aPriority !== -1 && bPriority !== -1) {
                return aPriority - bPriority;
            }

            // Finally, sort alphabetically by title
            return a.title.localeCompare(b.title);
        });
    }, [selectedCountry, selectedLanguage, t, handleCountryPress]);

    const filteredCountries = useMemo(() => {
        if (!debouncedSearchQuery) {
            return countries;
        }

        const query = debouncedSearchQuery.toLowerCase();
        return countries.filter((country) => country.title.toLowerCase().includes(query));
    }, [countries, debouncedSearchQuery]);

    const handleConfirm = useCallback(() => {
        if (!selectedCountry) {
            return;
        }

        const isNorway = selectedCountry === 'NO';
        if (isNorway) {
            track('Selected address country', { country: 'norway' });
            navigate('SelectAddress', params);
        } else {
            track('Selected address country', { country: selectedCountry });
            navigate('CreateRegistration', { ...params, address: null, initialGuess: null });
        }
    }, [navigate, params, selectedCountry]);

    // Render item function for FlashList
    const renderItem = useCallback(({ item }: { item: CountryItemType }) => {
        return <CountryItem {...item} />;
    }, []);

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: theme.mainBackground }}>
            <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
                <AuthNavigationHeader horizontalMargin />

                <HeimeText style={{ marginTop: screenMargin, marginHorizontal: screenMargin }} variant="title">
                    {t('selectAddressCountry:title')}
                </HeimeText>
                <View style={{ marginHorizontal: screenMargin, marginTop: screenMargin }}>
                    <ControlledInput
                        value={searchQuery}
                        onChange={setSearchQuery}
                        placeholder={t('selectAddressCountry:search')}
                        leftImage={<Icon name="search" color="secondaryText" />}
                        rightImage={
                            searchQuery ? (
                                <TouchableOpacity onPress={() => setSearchQuery('')} style={styles.clearButton}>
                                    <Icon name="cross" color="mediumGrey" />
                                </TouchableOpacity>
                            ) : null
                        }
                    />
                </View>

                <View style={{ flex: 1, marginTop: screenMargin }}>
                    {debouncedSearchQuery && filteredCountries.length === 0 ? (
                        <BlendedEmptyState
                            title={t('selectAddressCountry:noResultsTitle')}
                            subtitle={t('selectAddressCountry:noResultsMessage')}
                        />
                    ) : (
                        <FlashList
                            data={filteredCountries}
                            renderItem={renderItem}
                            estimatedItemSize={60}
                            keyboardShouldPersistTaps="handled"
                            contentContainerStyle={{ paddingBottom: screenMargin }}
                            ItemSeparatorComponent={() => <View style={{ height: screenMargin }} />}
                        />
                    )}
                </View>

                {keyboardVisible && !selectedCountry ? null : (
                    <View style={{ width: '100%', padding: screenMargin, flexShrink: 0 }}>
                        <PrimaryButton
                            status={selectedCountry ? null : 'disabled'}
                            onPress={handleConfirm}
                            text={t('selectAddressCountry:confirm')}
                        />
                    </View>
                )}
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

const CountryItem = ({
    title,
    icon,
    onPress,
    countryCode,
    selected,
}: {
    title: string;
    icon?: ComponentProps<typeof Icon>['name'];
    onPress: () => void;
    countryCode: string;
    selected: boolean;
}) => {
    const flagStyle = {
        width: 32,
        height: 32,
        borderRadius: 100,
        overflow: 'hidden',
    } as const;

    return (
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                paddingHorizontal: screenMargin,
                gap: screenMargin,
            }}
            onPress={onPress}
        >
            {icon ? (
                <View
                    style={{
                        width: 32,
                        height: 32,
                        borderRadius: 100,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon name={icon} color="main" scale={2} />
                </View>
            ) : (
                <FastImage
                    source={{
                        uri: `https://flagcdn.com/h40/${countryCode.toLowerCase()}.webp`,
                    }}
                    style={flagStyle}
                    resizeMode="cover"
                />
            )}
            <HeimeText style={{ fontSize: subtitleFontSize, flex: 1 }}>{title}</HeimeText>
            {selected ? <Icon name="check" color="main" style={{ marginLeft: 'auto' }} /> : null}
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    clearButton: {
        padding: 8,
    },
});

export default SelectAddressCountry;
