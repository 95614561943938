import { useCallback } from 'react';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { addBreadcrumb, sentryInterceptor } from './Sentry';
import { API_URL, APP_VERSION } from '../_constants';
import { AVAILABLE_LANGUAGES } from '../_localization';
import { useGetAccessToken } from '../Auth/AuthContext';

const initializeAxios = (): void => {
    axios.interceptors.response.use(logRequestThrottling, sentryInterceptor);
    axios.interceptors.request.use(addHeadersInterceptor);
};

const logRequestThrottling = (response: AxiosResponse): AxiosResponse => {
    const limit = response.headers['x-ratelimit-limit'] ? parseInt(response.headers['x-ratelimit-limit'], 10) : 0;
    if (response.headers['x-ratelimit-remaining'] && limit === 600) {
        const limitInfo = parseInt(response.headers['x-ratelimit-remaining'], 10);
        addBreadcrumb('rateLimitInfo', `Current value:${limitInfo}`);
    }
    return response;
};

// DEFAULT
axios.defaults.baseURL = API_URL('no');

const setBaseUrl = (lang: keyof typeof AVAILABLE_LANGUAGES): void => {
    axios.defaults.baseURL = API_URL(lang);
};

axios.defaults.timeout = 30 * 1000;

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-App-Version': APP_VERSION,
};

const addHeadersInterceptor = (
    config: InternalAxiosRequestConfig & { disableDefaultHeaders?: boolean },
): InternalAxiosRequestConfig => {
    if (config.disableDefaultHeaders) {
        return config;
    }
    const configHeaders = config.headers;
    configHeaders.Accept = headers.Accept;
    configHeaders['Content-Type'] = configHeaders['Content-Type'] ?? headers['Content-Type'];
    configHeaders['X-App-Version'] = headers['X-App-Version'];
    return { ...config };
};

const useGetAccessTokenHeader = (): (() => Promise<string>) => {
    const getAccessToken = useGetAccessToken();
    return useCallback(async () => {
        const token = await getAccessToken();
        return `Bearer ${token}`;
    }, [getAccessToken]);
};

export { initializeAxios, useGetAccessTokenHeader, setBaseUrl };
