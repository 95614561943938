import { useEffect } from 'react';
import { useGetMessages } from '_api/useMessages';
import { flattenIniniteResult } from '_utils/misc';
import { MessageType } from 'types/message';

const useIsHelpfulNeighbor = (ownId: number) => {
    const { data: messages, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMessages([MessageType.Helpers]);

    const flattenedMessages = flattenIniniteResult(messages);
    const isHelpfulNeighbour = flattenedMessages.some((message) => message.creator_id === ownId);

    useEffect(() => {
        if (!messages) {
            return;
        }

        if (!isHelpfulNeighbour && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [messages, isHelpfulNeighbour, hasNextPage, isFetchingNextPage, fetchNextPage]);

    return isHelpfulNeighbour;
};

export default useIsHelpfulNeighbor;
