import React, { ReactElement, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Edge, useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { useCreateRegistration, useUpdateRegistration } from '_api/useRegistrations';
import { SafeAreaView } from '_dependencies/safeArea';
import { setRegistration } from '_redux/pendingRegistration';
import { Theme, isAndroid, isWeb, showToast, useThemeStyle } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, smallestMarginPixels } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import {
    PrimaryButton,
    BackArrow,
    LanguageSelector,
    HeimeText,
    FormInput,
    Footer,
    ErrorMessage,
    PhoneNumberInput,
    Icon,
} from 'Components';
import MenuProvider from 'Components/MenuProvider';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const baseSchema = z.object({
    fname: z.string().min(1, 'onboarding:firstName_error').max(255),
    lname: z.string().min(1, 'onboarding:lastName_error').max(255),
    email: z.union([z.string().email('onboarding:invalidEmail'), z.string().length(0)]).nullable(),
    phone: z.string().min(1, 'onboarding:phone_error').max(24),
});

const schema = z.union([
    baseSchema.extend({
        cooperative_guess: z.string().min(1, 'createRegistration:project_error').max(1023),
        apartment_guess: z.string().min(1, 'createRegistration:apartment_error').max(1023),
        norwegian_user_registration: z.null(),
    }),
    baseSchema.extend({
        norwegian_user_registration: z.object({
            kommunenummer: z.string(),
            gardsnummer: z.string(),
            bruksnummer: z.string(),
            adressenavn: z.string(),
            nummer: z.string(),
            bokstav: z.string(),
            bruksenhetsnummer: z.string(),
            postnummer: z.string(),
            poststed: z.string(),
            matrikkel_addresse: z.string(),
        }),
        apartment_guess: z.string(),
        cooperative_guess: z.string(),
    }),
]);

const getInitialGuess = (initialGuess: string | null) => {
    if (initialGuess) {
        const items = initialGuess.split(' ');
        return {
            cooperative_guess: items[0],
            apartment_guess: items.slice(1).join(' '),
        };
    }
    return {
        cooperative_guess: '',
        apartment_guess: '',
    };
};

export type FormValues = z.infer<typeof schema>;

const CreateRegistration = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'CreateRegistration' | 'RegistrationEdit'>();
    const dispatch = useDispatch();
    const isEditing = 'registrationId' in params;

    const formValues = useForm<FormValues>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: isEditing
            ? params.data
            : {
                  fname: '',
                  lname: '',
                  email: '',
                  phone: params.phone,
                  ...getInitialGuess(params.initialGuess),
                  norwegian_user_registration: params.address ?? null,
              },
        resolver: zodResolver(schema),
    });

    const handleGoBack = () => {
        navigation.pop();
    };

    const { mutate: create, isPending: isCreating } = useCreateRegistration();
    const { mutate: update, isPending: isUpdating } = useUpdateRegistration(
        isEditing ? params.registrationId : 'justCreating',
    );

    const handleSubmit = (values: FormValues) => {
        const data = {
            ...values,
            email: values.email ?? '',
            phone: !isEditing ? params.phone : (values.phone ?? ''),
        };

        const onSuccess = () => navigation.replace('RegistrationSelected');
        if (isEditing) {
            update(data, {
                onSuccess,
                onError: () => {
                    showToast({
                        header: t('createRegistration:updateError'),
                        text: t('createRegistration:updateErrorText'),
                        type: 'error',
                    });
                },
            });
        } else {
            create(data, {
                onSuccess: (arg) => {
                    dispatch(setRegistration(arg));
                    setTimeout(() => onSuccess(), 300);
                },
                onError: () => {
                    showToast({
                        header: t('createRegistration:createError'),
                        text: t('createRegistration:createErrorText'),
                        type: 'error',
                    });
                },
            });
        }
    };

    const errorMessage = Object.values(formValues.formState.errors).reduce(
        (curr, val) => (curr ? curr : (val.message ?? '')),
        '',
    );

    const insets = useSafeAreaInsets();

    useEffect(() => {
        if ('editedAddress' in params) {
            formValues.setValue('norwegian_user_registration', params.editedAddress ?? null);
        }
    }, [formValues, params]);

    const handleEditAddress = () => {
        if ('data' in params) {
            navigation.navigate('SelectAddressEdit', {
                phone: params.data.phone,
                editParams: { data: params.data, registrationId: params.registrationId },
            });
        } else {
            navigation.navigate('SelectAddress', {
                phone: params.phone,
            });
        }
    };

    const norwegianRegistration = formValues.watch('norwegian_user_registration');

    return (
        <SafeAreaView
            style={themedStyle.container}
            edges={['bottom', 'left', 'right', isEditing ? undefined : 'top'].filter(Boolean) as Edge[]}
        >
            <GestureHandlerRootView style={themedStyle.container}>
                <MenuProvider>
                    <KeyboardAvoidingView
                        style={themedStyle.flexGrow}
                        behavior="padding"
                        keyboardVerticalOffset={insets.bottom}
                        enabled={!isAndroid()}
                    >
                        <View style={themedStyle.navWrapper}>
                            <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                                <BackArrow />
                                <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
                            </TouchableOpacity>
                            <LanguageSelector />
                        </View>
                        <ScrollView
                            bounces={false}
                            style={themedStyle.flex}
                            contentContainerStyle={themedStyle.containerStyle}
                            keyboardShouldPersistTaps="handled"
                        >
                            <HeimeText variant="title">{t('createRegistration:title')}</HeimeText>
                            <HeimeText variant="subtitle">{t('createRegistration:subtitle')}</HeimeText>
                            <FormProvider {...formValues}>
                                {norwegianRegistration ? (
                                    <View
                                        style={{
                                            gap: smallestMargin,
                                            marginTop: screenMargin,
                                            padding: smallestMargin,
                                            borderWidth: 1,
                                            borderColor: theme.secondary,
                                            borderRadius: smallestMargin,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                backgroundColor: theme.lightGreen,
                                                paddingVertical: smallestMargin / 2,
                                                paddingHorizontal: smallestMargin,
                                                position: 'absolute',
                                                top:
                                                    -smallestMarginPixels -
                                                    (isWeb() ? smallestMarginPixels : smallestFontSize / 2),
                                                right: smallestMarginPixels * 2,
                                            }}
                                        >
                                            <HeimeText
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: theme.main,
                                                    fontSize: smallestFontSize,
                                                }}
                                            >
                                                {t('createRegistration:selected')}
                                            </HeimeText>
                                        </View>
                                        <View
                                            style={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: smallestMargin,
                                                }}
                                            >
                                                <Icon name="home" color="main" scale={0.8} />
                                                <HeimeText
                                                    style={{ fontWeight: 'bold' }}
                                                >{`${norwegianRegistration.adressenavn} ${norwegianRegistration.nummer}${norwegianRegistration.bokstav} ${norwegianRegistration.bruksenhetsnummer}`}</HeimeText>
                                            </View>
                                            <HeimeText
                                                style={{
                                                    color: theme.mediumGrey,
                                                    fontSize: smallestFontSize,
                                                    lineHeight: smallestFontSize * 1.5,
                                                }}
                                            >
                                                {`${norwegianRegistration.postnummer} ${norwegianRegistration.poststed}`}
                                            </HeimeText>
                                            <HeimeText
                                                style={{
                                                    color: theme.mediumGrey,
                                                    fontSize: smallestFontSize,
                                                    lineHeight: smallestFontSize * 1.5,
                                                }}
                                            >
                                                {`${norwegianRegistration.matrikkel_addresse}`}
                                            </HeimeText>
                                        </View>
                                        <TouchableOpacity
                                            style={{ flexGrow: 0, padding: smallestMargin, alignItems: 'center' }}
                                            onPress={handleEditAddress}
                                        >
                                            <Icon name="edit" color="main" />
                                        </TouchableOpacity>
                                    </View>
                                ) : null}
                                <View>
                                    <View style={themedStyle.flexRow}>
                                        <View style={themedStyle.halfSize}>
                                            <FormInput
                                                name="fname"
                                                autoComplete="given-name"
                                                label={t('createRegistration:firstNameLabel')}
                                            />
                                        </View>
                                        <View style={themedStyle.halfSize}>
                                            <FormInput
                                                name="lname"
                                                autoComplete="family-name"
                                                label={t('createRegistration:lastNameLabel')}
                                            />
                                        </View>
                                    </View>
                                    {isEditing ? (
                                        <Controller
                                            name="phone"
                                            render={({ field }) => (
                                                <PhoneNumberInput
                                                    label={t('createRegistration:phoneLabel')}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    ) : null}
                                    <FormInput
                                        name="email"
                                        keyboardType="email-address"
                                        autoComplete="email"
                                        optional
                                        optionalText=""
                                        label={t('createRegistration:emailLabel')}
                                    />
                                    {norwegianRegistration ? null : (
                                        <>
                                            <FormInput
                                                name="cooperative_guess"
                                                multiline
                                                numberOfLines={2}
                                                autoComplete="street-address"
                                                placeholder={t('createRegistration:projectPlaceholder')}
                                                label={t('createRegistration:projectLabel')}
                                            />
                                            <FormInput
                                                name="apartment_guess"
                                                placeholder={t('createRegistration:apartmentPlaceholder')}
                                                label={t('createRegistration:apartmentLabel')}
                                            />
                                        </>
                                    )}
                                </View>
                                <View style={themedStyle.grower} />
                                {errorMessage ? (
                                    <ErrorMessage>{t(errorMessage as 'global:noLang')}</ErrorMessage>
                                ) : null}
                                <HeimeText style={{ marginBottom: screenMargin }} variant="subtitle">
                                    {t('createRegistration:gdpr_info')}
                                </HeimeText>
                            </FormProvider>
                        </ScrollView>
                        <PrimaryButton
                            status={
                                !formValues.formState.isValid || errorMessage
                                    ? 'disabled'
                                    : isCreating || isUpdating
                                      ? 'loading'
                                      : null
                            }
                            style={themedStyle.button}
                            onPress={formValues.handleSubmit(handleSubmit)}
                            text={t('createRegistration:send')}
                            bottomAction
                        />
                    </KeyboardAvoidingView>
                    <Footer />
                </MenuProvider>
            </GestureHandlerRootView>
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        flexGrow: { flexGrow: 1 },
        navWrapper: { flexDirection: 'row', alignItems: 'center', paddingHorizontal: screenMargin },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        containerStyle: {
            paddingHorizontal: screenMargin,
            flexGrow: 1,
            gap: smallestMargin,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
        flexRow: {
            flexDirection: 'row',
            gap: smallestMargin,
            width: '100%',
        },
        halfSize: {
            flexBasis: '40%',
            flexGrow: 1,
        },
        flex: {
            flex: 1,
        },
        grower: { flexGrow: 1, minHeight: screenMargin },
        button: { marginBottom: smallestMargin },
    });

export default CreateRegistration;
