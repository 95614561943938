import { useEffect } from 'react';
import { useOwnProfile } from '_api/useProfile';
import { useAppDispatch, useGlobalState } from '_utils';
import { useSelectedCoop } from 'SelectedCoop';
import {
    setAmplitudeUserId,
    removeAmplitudeUserId,
    setAmplitudeCooperative,
    removeAmplitudeCooperative,
    setAmplitudeLanguage,
    removeAmplitudeLanguage,
} from './Amplitude';
import { useSelectedCoopItem } from './hooks';
import { setSentryUser, removeSentryUser, setSentryCooperative, removeSentryCooperative } from './Sentry';
import { setUserId } from '../_redux/settings';

export const useRegisterUserContext = (): void => {
    const { data: profile } = useOwnProfile();
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const globalDispatch = useAppDispatch();

    useEffect(() => {
        if (profile) {
            setSentryUser(profile.id + '');
            setAmplitudeUserId(profile.id + '');
            globalDispatch(setUserId(profile.id + ''));
        }
        return () => {
            removeSentryUser();
            removeAmplitudeUserId();
        };
    }, [globalDispatch, profile]);

    useEffect(() => {
        if (selectedLanguage) {
            setAmplitudeLanguage(selectedLanguage);
        }
        return () => {
            removeAmplitudeLanguage();
        };
    }, [selectedLanguage]);
};

export const useSelectedCoopContext = (): void => {
    const selectedCoopId = useSelectedCoop();
    const selectedcoop = useSelectedCoopItem();

    useEffect(() => {
        if (selectedCoopId) {
            setSentryCooperative({ id: selectedCoopId, name: selectedcoop?.name ?? '' });
            setAmplitudeCooperative({ id: selectedCoopId, name: selectedcoop?.name ?? '' });
        }
        return () => {
            removeSentryCooperative();
            removeAmplitudeCooperative();
        };
    }, [selectedCoopId, selectedcoop]);
};
