import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import { NavigationContainer, ParamListBase, NavigationContainerRef } from '@react-navigation/native';
import useTrackNavigation from '_navigator/hooks/useTrackNavigation';
import { UpdateAppAlert } from 'Screens/GlobalModals';
import { useLogoutWithoutContext } from './AuthContext';
import { AuthStack } from './AuthNavigation';
import {
    SelectRole,
    Container,
    CreateRegistration,
    EnterPhone,
    EnterPin,
    LoggedInGate,
    SelectNumber,
    RegistrationSelected,
} from './components';
import LeadForm from './components/LeadForm';
import NonOwnerInstructions from './components/NonOwnerInstructions';
import SelectAddress from './components/SelectAddress';
import SelectAddressCountry from './components/SelectAddressCountry';
import { loggedInState, setSessionInfo } from '../_redux/settings';
import { useGlobalState } from '../_utils';
import useAppDispatch from '../_utils/hooks/useAppDispatch';

const Auth = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const sessionInfo = useGlobalState((state) => state.settings.sessionInfo);
    const registrationId = useGlobalState((state) => state.pendingRegistration);
    const dispatch = useAppDispatch();
    const navigationRef = useRef<NavigationContainerRef<ParamListBase>>(null);

    const { onReady, onStateChange } = useTrackNavigation(navigationRef);

    const handleNewTokens = (result: loggedInState) => {
        dispatch(setSessionInfo(result));
    };

    const handleLogout = useLogoutWithoutContext();

    if (sessionInfo?.isLoggedIn) {
        return (
            <LoggedInGate state={sessionInfo} onRefreshToken={handleNewTokens} onLogout={handleLogout}>
                {children}
            </LoggedInGate>
        );
    } else {
        return (
            <NavigationContainer
                ref={navigationRef}
                onReady={onReady}
                onStateChange={onStateChange}
                documentTitle={{ formatter: () => 'Heime' }}
            >
                <UpdateAppAlert />
                <AuthStack.Navigator
                    initialRouteName={registrationId !== null ? 'RegistrationSelected' : 'EnterPhone'}
                    screenOptions={{ headerShown: false }}
                >
                    <AuthStack.Screen name="EnterPhone">
                        {() => (
                            <Container>
                                <EnterPhone />
                            </Container>
                        )}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="EnterPin">
                        {() => (
                            <Container>
                                <EnterPin onComplete={handleNewTokens} />
                            </Container>
                        )}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="SelectNumber">
                        {() => (
                            <Container>
                                <SelectNumber />
                            </Container>
                        )}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="SelectRole">
                        {() => (
                            <Container>
                                <SelectRole />
                            </Container>
                        )}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="CreateRegistration">{() => <CreateRegistration />}</AuthStack.Screen>
                    <AuthStack.Screen name="SelectAddressCountry">{() => <SelectAddressCountry />}</AuthStack.Screen>
                    <AuthStack.Screen name="SelectAddress">{() => <SelectAddress />}</AuthStack.Screen>
                    {registrationId ? (
                        <AuthStack.Screen name="RegistrationSelected">
                            {() => <RegistrationSelected registrationId={registrationId} />}
                        </AuthStack.Screen>
                    ) : null}
                    <AuthStack.Screen name="RegistrationEdit" options={{ presentation: 'modal' }}>
                        {() => <CreateRegistration />}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="SelectAddressEdit" options={{ presentation: 'modal' }}>
                        {() => <SelectAddress />}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="LeadForm" options={{ presentation: 'modal' }}>
                        {() => (
                            <Container>
                                <LeadForm />
                            </Container>
                        )}
                    </AuthStack.Screen>
                    <AuthStack.Screen name="NonOwnerInstructions">
                        {() => (
                            <Container>
                                <NonOwnerInstructions />
                            </Container>
                        )}
                    </AuthStack.Screen>
                </AuthStack.Navigator>
            </NavigationContainer>
        );
    }
};

export default Auth;
