import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { createNativeStackNavigator, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { NorwegianUserRegistration, Registration } from '_api/useRegistrations';

type PinStatus = 'enterOTP' | 'enterOwnPin' | 'ownPinFailed';

type AuthStackNavigation = {
    EnterPhone: undefined | { enteredPhone: string } | { phoneHint?: string };
    EnterPin: { status: PinStatus; phone: string };
    SelectNumber: { firstNumber: string; secondNumber: string };
    SelectRole: { phone: string };
    NonOwnerInstructions: undefined;
    CreateRegistration: { phone: string; address: NorwegianUserRegistration | null; initialGuess: string | null };
    RegistrationSelected: undefined;
    RegistrationEdit: {
        data: Registration;
        registrationId: string;
        editedAddress?:
            | (NorwegianUserRegistration & {
                  postnummer: string;
                  poststed: string;
                  matrikkel_addresse: string;
              })
            | null;
    };
    SelectAddress: {
        phone: string;
    };
    SelectAddressEdit: {
        phone: string;
        editParams: { data: Registration; registrationId: string };
    };
    SelectAddressCountry: { phone: string };
    LeadForm: { phone: string };
};

const AuthStack = createNativeStackNavigator<AuthStackNavigation>();

const useAuthNavigation = <Key extends keyof AuthStackNavigation>(): NativeStackNavigationProp<
    AuthStackNavigation,
    Key
> => useNavigation<NativeStackNavigationProp<AuthStackNavigation, Key>>();

const useAuthRoute = <Key extends keyof AuthStackNavigation>(): RouteProp<AuthStackNavigation, Key> =>
    useRoute<RouteProp<AuthStackNavigation, Key>>();

export { AuthStack, useAuthNavigation, useAuthRoute };
