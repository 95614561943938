import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Images from '_images';
import { WW } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeimeText, Icon, CacheImage } from 'Components';
import AuthNavigationHeader from './common/AuthNavigationHeader';

const NonOwnerInstructions = (): ReactElement => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();

    return (
        <>
            <AuthNavigationHeader />
            <View
                style={{
                    marginBottom: screenMargin,
                    gap: smallestMargin,
                    marginTop: screenMargin,
                    flex: 1,
                    justifyContent: 'space-between',
                }}
            >
                <View style={{ gap: smallestMargin }}>
                    <HeimeText variant="title">{t('nonOwnerInstructions:title')}</HeimeText>
                    <HeimeText variant="subtitle" style={{ alignSelf: 'center' }}>
                        {t('nonOwnerInstructions:subtitle')}
                    </HeimeText>
                </View>
                <View
                    style={{
                        backgroundColor: theme.lightGreen,
                        width: '100%',
                        alignItems: 'center',
                        paddingHorizontal: screenMargin,
                    }}
                >
                    <View
                        style={{ width: '70%', paddingVertical: screenMargin, backgroundColor: theme.mainBackground }}
                    >
                        <CacheImage
                            resizeMode="contain"
                            source={Images.nonOwnerInstructions}
                            style={{ width: '100%', height: WW * 0.3 }}
                        />
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            gap: smallestMargin,
                            width: '70%',
                            backgroundColor: theme.mainBackground,
                            paddingVertical: smallestMargin,
                            borderWidth: 1,
                            borderColor: theme.main,
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: 10,
                        }}
                    >
                        <HeimeText style={{ color: theme.main, fontSize: subtitleFontSize, fontWeight: 'bold' }}>
                            {t('myHome:addTenant')}
                        </HeimeText>
                    </View>
                </View>
                <View style={{ gap: smallestMargin }}>
                    <View style={{ flexDirection: 'row', gap: smallestMargin, alignItems: 'center' }}>
                        <Icon name="info" color="main" />
                        <HeimeText style={{ flex: 1 }} variant="subtitle">
                            {t('nonOwnerInstructions:how_to_add')}
                        </HeimeText>
                    </View>
                    <View style={{ flexDirection: 'row', gap: smallestMargin, alignItems: 'center' }}>
                        <Icon name="info" color="main" />
                        <HeimeText style={{ flex: 1 }} variant="subtitle">
                            {t('nonOwnerInstructions:why')}
                        </HeimeText>
                    </View>
                </View>
            </View>
        </>
    );
};

export default NonOwnerInstructions;
