import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useOwnProfile } from '_api/useProfile';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import useIsHelpfulNeighbor from '_utils/messages/useIsHelpfulNeighbor';
import ActionBanner from 'Components/ActionBanner';
import { MessageType } from 'types/message';
import SharingCategorySelected from './SharingAll/SharingCategorySelected';

const HelpfulNeighbor = (): ReactElement => {
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { data: ownProfile } = useOwnProfile();
    const isHelpfulNeighbor = useIsHelpfulNeighbor(ownProfile?.id ?? 0);

    const handleBecomeHelpfulNeighbor = () => {
        track('Pressed become helpful neighbor banner in Helpful Neighbor overview');
        navigate('SharingNew', { type: MessageType.Helpers });
    };

    return (
        <SharingCategorySelected
            types={[MessageType.Helpers]}
            header={t('sharingSelected:messageType:Helpers')}
            banner={
                isHelpfulNeighbor ? null : (
                    <ActionBanner
                        onPress={handleBecomeHelpfulNeighbor}
                        title={t('sharingAll:becomeHelpfulNeighbor')}
                        message={t('sharingAll:becomeHelpfulNeighborDescription')}
                        icon="users"
                        variant="green-light"
                    />
                )
            }
            emptyText={t('sharingAll:noMessageType:Helpers')}
        />
    );
};

export default HelpfulNeighbor;
