import React, { ComponentProps, ReactElement } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useThemeContext } from '_utils/themeContext';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';
import { WW } from '../_utils';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '../_utils/sizes';

interface ActionBannerProps {
    onPress: () => void;
    title: string;
    message: string;
    icon: ComponentProps<typeof Icon>['name'];
    variant: 'mustard' | 'mustard-focus' | 'green-light';
}

const ActionBanner = ({ onPress, title, message, icon, variant }: ActionBannerProps): ReactElement => {
    const { theme } = useThemeContext();

    const baseMustardStyle = {
        messageContainer: {
            borderBottomColor: theme.lightGrey,
            borderTopColor: theme.lightGrey,
        },

        iconWrapper: {
            backgroundColor: theme.lightGreen,
        },
        icon: {
            color: 'main',
        },
        title: {
            fontSize: WW * 0.04,
        },
        subTitle: {
            color: theme.secondary,
        },
        chevron: {
            color: 'black',
        },
    } as const;

    const style = (
        {
            'green-light': {
                messageContainer: {
                    borderColor: theme.secondary,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    borderRadius: WW * 0.05,
                },
                iconWrapper: {
                    backgroundColor: theme.lightGreen,
                },
                icon: {
                    color: 'main',
                },
                title: {
                    fontSize: subtitleFontSize,
                },
                subTitle: {
                    color: theme.greyMedium,
                },
                chevron: {
                    color: 'main',
                },
            },
            'mustard-focus': {
                ...baseMustardStyle,
                title: {
                    color: theme.main,
                    fontWeight: 'bold',
                    fontSize: subtitleFontSize,
                },
            },
            mustard: baseMustardStyle,
        } as const
    )[variant];

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[
                style.messageContainer,
                {
                    paddingTop: WW * 0.04,
                    paddingBottom: WW * 0.04,
                    paddingLeft: WW * 0.04,
                    paddingRight: WW * 0.04,
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: screenMargin,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                },
            ]}
        >
            <View
                style={[
                    style.iconWrapper,
                    {
                        paddingTop: WW * 0.02,
                        paddingBottom: WW * 0.02,
                        paddingLeft: WW * 0.02,
                        paddingRight: WW * 0.02,
                        borderRadius: WW * 0.1,
                    },
                ]}
            >
                <Icon name={icon} scale={1} color={style.icon.color} />
            </View>
            <View style={{ flex: 1, gap: smallestMargin }}>
                <HeimeText style={[{ color: theme.black, fontWeight: 'bold', fontSize: WW * 0.04 }, style.title]}>
                    {title}
                </HeimeText>
                <HeimeText style={[{ fontSize: smallestFontSize }, style.subTitle]}>{message}</HeimeText>
            </View>
            <Icon name="chevron" color={style.chevron.color} />
        </TouchableOpacity>
    );
};

export default ActionBanner;
