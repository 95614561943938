import React, { ReactElement } from 'react';
import { View, StyleSheet } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { HeaderWithNav } from 'Components';
import { MessageType } from 'types/message';
import { MessageList, useSharingContextMenuEffect } from '../common';

interface SharingCategorySelectedProps {
    types: Exclude<MessageType, MessageType.Requests | MessageType.ByBoard>[];
    header: string;
    emptyText: string;
    banner?: ReactElement | null;
}

const SharingCategorySelected = ({ types, header, emptyText, banner }: SharingCategorySelectedProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    useSharingContextMenuEffect(types);

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={header} safeArea />
            <MessageList top={banner ?? undefined} emptyText={emptyText} messageTypes={types} numColumns={2} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
    });

export default SharingCategorySelected;
